import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupUserRoleService = {
  list: () => {
    return supabase
      .from('roles')
      .select("*, value:id")
      .then(response => response.data)
      .catch(err => err)
  },
  save: (role) => CommonService.save('roles', role),
  get: (id, onSuccess, onError) => CommonService.get('roles', id, onSuccess, onError),
  delete: (id) => CommonService.delete('roles', id)
}