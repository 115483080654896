import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const JobWorkService = {
  list: (id) => {
    return supabase
      .from('job_works')
      .select('*,work:lookup_values(*),subItems:job_work_metas(*, workmeta:lookup_child_values(*))')
      .eq('job_id', id)
      .then(response => response.data)
      .catch(err => err)
  },
  saveBulk: (works) => {
    return supabase
        .from('job_works')
        .insert(works)
        .then(savedItem => savedItem)
        .catch(err => err)
  },
  save: (work, onSuccess, onError) => CommonService.save('job_works', work, onSuccess, onError),
  get: (id) => {
    return supabase
      .from('job_works')
      .select('*')
      .eq('job_id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  deleteAllByJobId:  (job_id) => {
    return supabase
      .from('job_works')
      .delete()
      .eq('job_id', job_id)
      .then(deletedItem => deletedItem)
      .catch(err => err)
  },
  deleteAllByIds:  (jobWorkIds) => {
    return supabase
      .from('job_works')
      .delete()
      .in('id', jobWorkIds)
      .then(deletedItem => deletedItem)
      .catch(err => err)
  }

}
