// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupUserRoleService} from '@services/lookup-user-role-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'role',
  sort_order: 0
}

export const getRoles = createAsyncThunk('appRoles/getRoles', async () => {
    try { 
      const response = await LookupUserRoleService.list()
      return { roles: response, roleTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertRoles = createAsyncThunk('appRoles/upsertRoles', async (item, { dispatch }) => {
    try { 
      const response = await LookupUserRoleService.save(item)
      await dispatch(getRoles())
      await dispatch(setRoleModal(false))
      await dispatch(selectRole())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteRoles = createAsyncThunk('appRoles/deleteRoles', async (id, { dispatch }) => {
    try {
      const response = await LookupUserRoleService.delete(id)
      await dispatch(getRoles())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appRolesSlice = createSlice({
  name: 'appRoles',
  initialState: {
    selectedRole: defaultValues,
    roles: [],
    roleTableLoader: true,
    rolesModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.roleTableLoader = !state.roleTableLoader
    },
    setRoleModal: (state, action) => {
    if (state.rolesModal) {
        state.selectedRole = defaultValues
    }
    state.rolesModal = action.payload
    },
    selectRole: (state, action) => {
        state.selectedRole = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roleTableLoader = action.payload.roleTableLoader
      state.roles = action.payload.roles
    })
  }
})

export const { setLoading, setTableLoader, setRoleModal, selectRole } = appRolesSlice.actions

export default appRolesSlice.reducer