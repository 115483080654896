// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getArtistImpressionAgreements = createAsyncThunk('appArtistImpressionAgreements/getArtistImpressionAgreements', async (jobId) => {
    try { 
      const categories = ['artist-impression-service-agreement']
      const response = await DocumentService.list(jobId, categories)

      return { artistImpressionAgreements: response, artistImpressionAgreementTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appArtistImpressionAgreementsSlice = createSlice({
  name: 'appArtistImpressionAgreements',
  initialState: {
    selectedArtistImpressionAgreements: defaultValues,
    artistImpressionAgreements: [],
    artistImpressionAgreementTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.artistImpressionAgreementTableLoader = !state.artistImpressionAgreementTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getArtistImpressionAgreements.fulfilled, (state, action) => {
      state.artistImpressionAgreementTableLoader = action.payload.artistImpressionAgreementTableLoader
      state.artistImpressionAgreements = action.payload.artistImpressionAgreements
    })
  }
})

export const { setLoading, setTableLoader } = appArtistImpressionAgreementsSlice.actions

export default appArtistImpressionAgreementsSlice.reducer