import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const ProjectProfileService = {
  save: (profile, onSuccess, onError) => CommonService.save('job_profiles', profile, onSuccess, onError),
  get: (id) => {
    return supabase
      .from('job_profiles')
      .select('*')
      .eq('job_id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  delete: (id) => CommonService.delete('job_profiles', id)
}
