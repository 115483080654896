import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const OpportunityChecklistService = {
  list: () => CommonService.list('opportunity_checklists'),
  save: (opportunityChecklist, onSuccess, onError) => CommonService.save('opportunity_checklists', opportunityChecklist, onSuccess, onError),
  get: (id) => CommonService.get('opportunity_checklists', id),
  delete: (id) => CommonService.delete('opportunity_checklists', id),
  checkClientProfileByJobId: (job_id) => {
    return supabase
      .from('client_profiles')
      .select('*')
      .eq('job_id', job_id)
      .then(response => response.data)
      .catch(err => err)
  },
  checkDesignProfileByJobId: (job_id) => {
    return supabase
      .from('design_profiles')
      .select('*')
      .eq('job_id', job_id)
      .then(response => response.data)
      .catch(err => err)
  },
  checkUploadedDocumentsByJobId: (job_id) => {
    return supabase
      .from('documents')
      .select('*')
      .match({
        job_id,
        category: 'original-floor-plan'
      })
      .then(response => response.data)
      .catch(err => err)
  }
}

