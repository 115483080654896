// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { EmployeeService } from '@services/employee-service'
import { UserRoleService } from '@services/user-role-service'
import { TeamService } from '@services/team-service'

import { UserService } from '@services/user-service'


const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  mobile: '',
  nric: '',
  bank: '',
  bank_number: '',
  department_id: '',
  employee_position_id: ''
}

export const getEmployees = createAsyncThunk('appEmployees/getEmployees', async () => {
  try {
    const response = await EmployeeService.list()
    return { employees: response, employeeTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const getTeamLeaders = createAsyncThunk('appUsers/getTeamLeaders', async () => {
  try {
    const response = await EmployeeService.getTeamLeaders()

    return { leaders: response, userTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const upsertEmployees = createAsyncThunk('appEmployees/upsertEmployees', async (item, { dispatch }) => {
  try {
    console.log("emop", item)
    const response = await EmployeeService.save(item)
    await dispatch(getEmployees())
    await dispatch(setEmployeeModal(false))
    await dispatch(selectEmployee())

    return response
  } catch (error) {
    console.log(error)
  }
})

export const upsertUserRole = createAsyncThunk('appEmployees/upsertUserRole', async (item, { dispatch }) => {
  try {
    const response = await UserRoleService.save(item)
    await dispatch(getEmployees())
    await dispatch(setActivateUserModal(false))
    await dispatch(setUserRolesModal(false))
    await dispatch(selectEmployee())

    return response
  } catch (error) {
    console.log(error)
  }
})

export const deleteUserRoleAll = createAsyncThunk('appEmployees/deleteUserRoleAll', async (email, { dispatch }) => {
  try {
    const response = await UserRoleService.deleteAll(email)
    await dispatch(getEmployees())
    await dispatch(setActivateUserModal(false))
    await dispatch(setUserRolesModal(false))
    await dispatch(selectEmployee())

    return response
  } catch (error) {
    console.log(error)
  }
})

export const deleteUserTeamAll = createAsyncThunk('appEmployees/deleteUserTeamAll', async (employeeId) => {
  try {
    const response = await TeamService.deleteAll(employeeId)
    //const response = await TeamService.deleteAll()

    return response
  } catch (error) {
    console.log(error)
  }
})

export const upsertUserTeam = createAsyncThunk('appEmployees/upsertUserTeam', async (item, { dispatch }) => {
  try {
    const response = await TeamService.save(item)
    await dispatch(getEmployees())
    await dispatch(setActivateUserModal(false))
    await dispatch(setUserRolesModal(false))
    await dispatch(selectEmployee())

    return response
  } catch (error) {
    console.log(error)
  }
})

export const deleteUserRole = createAsyncThunk('appEmployees/deleteUserRole', async (id, { dispatch }) => {
  try {
    const response = await UserRoleService.delete(id)
    await dispatch(getEmployees())
    await dispatch(setActivateUserModal(false))
    await dispatch(setUserRolesModal(false))
    await dispatch(selectEmployee())
    return response
  } catch (error) {
    console.log(error)
  }
})


export const deleteUserTeam = createAsyncThunk('appEmployees/deleteUserTeam', async (id) => {
  try {
    const response = await TeamService.delete(id)
    return response
  } catch (error) {
    console.log(error)
  }
})


export const inviteUser = createAsyncThunk('appUsers/inviteUser', async (email_) => {
  const { access_token } = UserService.session()

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/invite/user?supabase_token=${access_token}&email=${email_}`, {
      method: 'GET'
      // redirect: 'follow'
    })
    const data = await response.json()

    return data
  } catch (error) {
    console.log(error)
  }
})


export const updateStatusEmployee = createAsyncThunk('appEmployees/updateStatusEmployee', async (item) => {
  try {
    const response = await EmployeeService.save(item)

    return response
  } catch (error) {
    console.log(error)
  }
})


export const deleteEmployees = createAsyncThunk('appEmployees/deleteEmployees', async (id, { dispatch }) => {
  try {
    const response = await EmployeeService.delete(id)
    await dispatch(getEmployees())

    return response
  } catch (error) {
    console.log(error)
  }
})

export const appEmployeesSlice = createSlice({
  name: 'appEmployees',
  initialState: {
    selectedEmployee: defaultValues,
    employees: [],
    leaders: [],
    employeeTableLoader: true,
    employeesModal: false,
    userRolesModal: false,
    activateUserModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.employeeTableLoader = !state.employeeTableLoader
    },
    setEmployeeModal: (state, action) => {
      if (state.employeesModal) {
        state.selectedEmployee = defaultValues
      }
      state.employeesModal = action.payload
    },
    setUserRolesModal: (state, action) => {

      state.userRolesModal = action.payload
    },
    setActivateUserModal: (state, action) => {

      state.activateUserModal = action.payload
    },
    selectEmployee: (state, action) => {
      state.selectedEmployee = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.employeeTableLoader = action.payload.employeeTableLoader
      state.employees = action.payload.employees
    }),
      builder.addCase(getTeamLeaders.fulfilled, (state, action) => {
        state.leaders = action.payload.leaders
      })
  }
})

export const { setLoading, setTableLoader, setEmployeeModal, selectEmployee, setUserRolesModal, setActivateUserModal } = appEmployeesSlice.actions

export default appEmployeesSlice.reducer