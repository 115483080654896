import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupTimeService = {
  listTime: () => {
    return supabase
      .from('lookup_values')
      .select('*')
      .eq('type_', 'time')
      .then(response => response.data)
      .catch(err => err)
  },
  save: (time) => CommonService.save('lookup_values', time),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id) => CommonService.delete('lookup_values', id)
}