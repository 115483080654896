import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const JobWorkMetaService = {
  list: (jobWorkId) => {
    return supabase
      .from('job_work_metas')
      .select('*')
      .eq('job_work_id', jobWorkId)
      .then(response => response.data)
      .catch(err => err)
  },
  saveBulk: (items) => {
    return supabase
        .from('job_work_metas')
        .insert(items)
        .then(savedItem => savedItem)
        .catch(err => err)
  },
  save: (work, onSuccess, onError) => CommonService.save('job_work_metas', work, onSuccess, onError),
  get: (id) => {
    return supabase
      .from('job_work_metas')
      .select('*')
      .eq('id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  deleteAllByJobWorkIds:  (jobWorkIds) => {
    return supabase
      .from('job_work_metas')
      .delete()
      .in('job_work_id', jobWorkIds)
      .then(deletedItem => deletedItem)
      .catch(err => err)
  }
}
