import { CommonService } from "./common-service";
import { supabase } from "../configs/supabaseClient";
import { getUsers } from "../views/pages/users/store";
import { getUserData } from "@utils";

export const OpportunityService = {
  list: async (from, to) => {
    try {
      const getD = getUserData();

      const data = await supabase
        .rpc("fn_user_id_with_children", { uid: getD.id })
        .then((response) => response.data)
        .catch((err) => err);

      const d = getD.role.find((item) => item.name === "data-administrator");

      let response;
      if (!d?.name) {
        const employeeRepresntativeId = await supabase
          .from("employees")
          // .select("*, checklist:opportunity_checklists(*)")
          .select("id")
          .in("user_id", data)
          .then((response) => response.data)
          .catch((err) => err);

        response = await supabase
          .from("view_opportunities_v2")
          // .select("*, checklist:opportunity_checklists(*)")
          .select("*")
          .in(
            "representative_id",
            employeeRepresntativeId.map((obj) => obj.id)
          )
          .neq("status", "unqualified")
          .range(from, to)
          .order("created_at", { ascending: false })
          .then((response) => response.data)
          .catch((err) => err);
      } else {
        response = await supabase
          .from("view_opportunities_v2")
          // .select("*, checklist:opportunity_checklists(*)")
          .select("*")
          .range(from, to)
          .order("created_at", { ascending: false })
          .then((response) => response.data)
          .catch((err) => err);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  },
  listFirstAppt: (from, to) => {
    return (
      supabase
        .from("view_opportunities_v2")
        // .select("*, checklist:opportunity_checklists(*)")
        .select("*")
        .range(from, to)
        .eq("status", "first-appointment")
        .order("created_at", { ascending: false })
        .then((response) => response.data)
        .catch((err) => err)
    );
  },
  listPendingDocs: (from, to) => {
    return (
      supabase
        .from("view_opportunities_v2")
        // .select("*, checklist:opportunity_checklists(*)")
        .select("*")
        .range(from, to)
        .eq("status", "pending-document")
        .order("created_at", { ascending: false })
        .then((response) => response.data)
        .catch((err) => err)
    );
  },
  listOnGoing: (from, to) => {
    return (
      supabase
        .from("view_opportunities_v2")
        // .select("*, checklist:opportunity_checklists(*)")
        .select("*")
        .range(from, to)
        .eq("status", "on-going")
        .order("created_at", { ascending: false })
        .then((response) => response.data)
        .catch((err) => err)
    );
  },
  listUnqualified: (from, to) => {
    return (
      supabase
        .from("view_opportunities_v2")
        // .select("*, checklist:opportunity_checklists(*)")
        .select("*")
        .range(from, to)
        .eq("status", "unqualified")
        .order("created_at", { ascending: false })
        .then((response) => response.data)
        .catch((err) => err)
    );
  },
  // getlist: () => {
  //   return supabase
  //   .from('view_opportunities_v2')
  //   .select("*,communications:opportunity_communications(*),checklist:opportunity_checklists(*)")
  //   .then(response => console.log(response.data))
  //   .catch(err => err)
  // },
  // listCount: async () => {
  //   const { data: counts, error } = await supabase
  //     .from('view_opportunities_v2')
  //     .select(`
  //       count(*) as all,
  //       count(*) filter (where status = 'first-appointment') as firstAppointment,
  //       count(*) filter (where status = 'pending-document') as pendingDocument,
  //       count(*) filter (where status = 'on-going') as onGoing,
  //       count(*) filter (where status = 'unqualified') as unqualified
  //     `)
  //     .single()

  //   if (error) {
  //     throw error
  //   }

  //   return counts
  // },

  listCount: async () => {
    const getD = getUserData();
    console.log(getD);

    // fn_user_id_with_children
    const data = await supabase
      .rpc("fn_user_id_with_children", { uid: getD.id })
      .then((response) => response.data)
      .catch((err) => err);

    const d = getD.role.find((item) => item.name === "data-administrator");

    let getOpportunitiesCount;
    if (!d?.name) {
      getOpportunitiesCount = await supabase
        .rpc("fn_get_all_opportunities_count_with_params", {
          rep_id: getD.details.id,
        })
        .then((response) => response.data)
        .catch((err) => err);
    } else {
      getOpportunitiesCount = await supabase
        .rpc("fn_get_all_opportunities_count")
        .then((response) => response.data)
        .catch((err) => err);
    }

    const counts = {
      all: getOpportunitiesCount.all_count,
      firstAppointment: getOpportunitiesCount.first_appointment_count,
      pendingDocument: getOpportunitiesCount.pending_document_count,
      onGoing: getOpportunitiesCount.on_going_count,
      unqualified: getOpportunitiesCount.pending_document_count,
    };

    return counts;
  },
  // listCount: async () => {
  //  const all = await supabase
  //     .from('view_opportunities_v2')
  //     .select("id, status", { count: 'exact'})

  //     const firstAppointment = all.data.filter(el => el.status === 'first-appointment')
  //     const pendingDocument = all.data.filter(el => el.status === 'pending-document')
  //     const onGoing = all.data.filter(el => el.status === 'on-going')
  //     const unqualified = all.data.filter(el => el.status === 'unqualified')

  //     const counts = {
  //         all: all.data.length,
  //         firstAppointment: firstAppointment.length,
  //         pendingDocument: pendingDocument.length,
  //         onGoing: onGoing.length,
  //         unqualified: unqualified.length
  //      }

  //     return counts
  // },
  getCommunication: async (row) => {
    return supabase
      .from("communications")
      .select("*")
      .eq("job_id", row)
      .eq("stage", "opportunity")
      .order("created_at", { ascending: false })
      .then((response) => response.data)
      .catch((err) => err);
  },
  search: async (searchObject) => {
    const getD = getUserData();
    let query = supabase.from("view_opportunities_v2").select("*");

    const checkRoles = getD.role.find(
      (item) => item.name === "data-administrator"
    );

    if (!checkRoles?.name) {
      query = query.neq("status", "unqualified");
    }

    if (searchObject.name !== null) {
      query = query.ilike("lead_name", `%${searchObject.name}%`);
    }
    if (searchObject.status !== null) {
      query = query.eq("status", searchObject.status);
    }
    if (searchObject.assign_to !== null) {
      query = query.eq("representative_id", searchObject.assign_to);
    }
    if (searchObject.email !== null) {
      query = query.ilike("lead_email", searchObject.email);
    }
    if (searchObject.phone !== null) {
      query = query.ilike("lead_phone", searchObject.phone);
    }
    if (searchObject.from !== null && searchObject.to !== null) {
      query = query
        .gt("created_at", searchObject.from.toLocaleString())
        .lt("created_at", searchObject.to.toLocaleString());
    }

    const response = await query;

    return response.data;
  },
  bulkSaveOpportunities: async (row) => {
    return supabase
      .rpc("fn_update_multiple_opportunities", { payload: row })
      .then((response) => response.data)
      .catch((err) => err);
  },
  save: (opportunity, onSuccess, onError) =>
    CommonService.save("opportunities", opportunity, onSuccess, onError),
  getCommunicationHistory: (opportunityId, jobId) => {
    return supabase
      .rpc("fn_get_opportunity_communications_history", {
        val_opportunity_id: opportunityId,
        val_job_id: jobId,
      })
      .then((response) => response.data)
      .catch((err) => err);
  },
  get: (id) => {
    return supabase
      .from("view_opportunities_v2")
      .select("*,checklist:opportunity_checklists(*)")
      .eq("id", id)
      .single()
      .then((response) => response.data)
      .catch((err) => err);
  },
  getByJobId: (jobId) => {
    return supabase
      .from("view_opportunities_v2")
      .select("*,checklist:opportunity_checklists(*)")
      .eq("job_id", jobId)
      .order("created_at", { ascending: false })
      .limit(1)
      .single()
      .then((response) => response.data)
      .catch((err) => err);
  },
  delete: (id) => CommonService.delete("opportunities", id),
};

/*
create view view_opportunities_v2 as
    select
        o.*,
        (select oc.id from opportunity_communications as oc where oc.opportunity_id = o.id and oc.communicated_at is null limit 1) as communication_id
    from opportunities o
    left join leads l on l.job_id = o.job_id;

ALTER VIEW view_opportunities_v2 OWNER TO authenticated
*/
