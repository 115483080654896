// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupPositionService} from '@services/lookup-position-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'position',
  sort_order: 0
}

export const getPositions = createAsyncThunk('appPositions/getPositions', async () => {
    try { 
      const response = await LookupPositionService.list()
      return { positions: response, positionTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertPositions = createAsyncThunk('appPositions/upsertPositions', async (item, { dispatch }) => {
    try { 
      const response = await LookupPositionService.save(item)
      await dispatch(getPositions())
      await dispatch(setPositionModal(false))
      await dispatch(selectPosition())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deletePositions = createAsyncThunk('appPositions/deletePositions', async (id, { dispatch }) => {
    try {
      const response = await LookupPositionService.delete(id)
      await dispatch(getPositions())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appPositionsSlice = createSlice({
  name: 'appPositions',
  initialState: {
    selectedPosition: defaultValues,
    positions: [],
    positionTableLoader: true,
    positionsModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.positionTableLoader = !state.positionTableLoader
    },
    setPositionModal: (state, action) => {
    if (state.positionsModal) {
        state.selectedPosition = defaultValues
    }
    state.positionsModal = action.payload
    },
    selectPosition: (state, action) => {
        state.selectedPosition = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getPositions.fulfilled, (state, action) => {
      state.positionTableLoader = action.payload.positionTableLoader
      state.positions = action.payload.positions
    })
  }
})

export const { setLoading, setTableLoader, setPositionModal, selectPosition } = appPositionsSlice.actions

export default appPositionsSlice.reducer