// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {JobWorkMetaService} from '@services/job-work-meta-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'jobWorkMetas',
  sort_order: 0
}

export const getJobWorkMetas = createAsyncThunk('appJobWorkMetas/getJobWorkMetas', async (jobId) => {
    try { 
      const response = await JobWorkMetaService.list(jobId)
      return { jobWorkMetas: response}
    } catch (error) {
      console.log(error)
    }
})

export const upsertJobWorkMeta = createAsyncThunk('appJobWorkMetas/upsertJobWorkMeta', async (item, { dispatch }) => {
    try { 
      const response = await JobWorkMetaService.save(item)
      
      if (item.job_id) {
        await dispatch(getJobWorkMetas(item.job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const insertBulkJobWorkMetas = createAsyncThunk('appJobWorkMetas/insertBulkJobWorkMetas', async (items, { dispatch }) => {
    try { 
      const response = await JobWorkMetaService.saveBulk(items)
      
      if (items[0].job_id) {
        await dispatch(getJobWorkMetas(items[0].job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteJobWorkMetaByJobWorkIds = createAsyncThunk('appJobWorkMetas/deleteJobWorkMetaByJobWorkIds', async (jobWorkIds) => {
    try {
      const response = await JobWorkMetaService.deleteAllByJobWorkIds(jobWorkIds)
      //await dispatch(getJobWorkMetas(jobId))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appJobWorkMetasSlice = createSlice({
  name: 'appJobWorkMetas',
  initialState: {
    selectedJobWorkMeta: defaultValues,
    jobWorkMetas: []
  },
  reducers: {
    selectJobWorkMeta: (state, action) => {
        state.selectedJobWorkMeta = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getJobWorkMetas.fulfilled, (state, action) => {
      state.jobWorkMetas = action.payload.jobWorkMetas
    })
  }
})

export const { setLoading, setTableLoader, setJobWorkMetaModal, selectJobWorkMeta } = appJobWorkMetasSlice.actions

export default appJobWorkMetasSlice.reducer