// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getApplicationRequirements = createAsyncThunk('appApplicationRequirements/getApplicationRequirements', async (jobId) => {
    try { 
      const categories = ['original-floor-plan', 'site-video-photo', 'reference-design', 'off-the-shelve-furniture', 'existing-furniture', 'other-documents']
      const response = await DocumentService.list(jobId, categories)

      return { applicationRequirements: response, applicationRequirementTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appApplicationRequirementsSlice = createSlice({
  name: 'appApplicationRequirements',
  initialState: {
    selectedApplicationRequirements: defaultValues,
    applicationRequirements: [],
    applicationRequirementTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.applicationRequirementTableLoader = !state.applicationRequirementTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getApplicationRequirements.fulfilled, (state, action) => {
      state.applicationRequirementTableLoader = action.payload.applicationRequirementTableLoader
      state.applicationRequirements = action.payload.applicationRequirements
    })
  }
})

export const { setLoading, setTableLoader } = appApplicationRequirementsSlice.actions

export default appApplicationRequirementsSlice.reducer