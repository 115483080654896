import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupDesignConceptService = {
  // list: (from, to) => {
  //   return supabase
  //     .from('lookup_values')
  //     .select("*,value:id")
  //     .eq('type_', 'design-concept')
  //     .range(from, to)
  //     .order('sort_order', { ascending: true })
  //     .then(response => response.data)
  //     .catch(err => err)
  // },
   list: () => {
    return supabase
      .from('lookup_values')
      .select("*,value:id")
      .eq('type_', 'design-concept')
      .order('sort_order', { ascending: true })
      .then(response => response.data)
      .catch(err => err)
  },
   listCount: () => {
    return supabase
      .from('lookup_values')
      .select("*,value:id", { count: 'exact', head: true })
      .eq('type_', 'design-concept')
      .order('sort_order', { ascending: true })
      .then(response => response?.count)
      .catch(err => err)
  },
  save: (concept) => CommonService.save('lookup_values', concept),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id) => CommonService.delete('lookup_values', id)
}