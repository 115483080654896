import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupAreaService = {
  // list: (from, to) => {
  //   return supabase
  //     .from('lookup_values')
  //     .select('*,value:id')
  //     .eq('type_', 'area')
  //     .range(from, to)
  //     .order('sort_order', { ascending: true })
  //     .then(response => response.data)
  //     .catch(err => err)
  // },
  list: () => {
    return supabase
      .from('lookup_values')
      .select('*,value:id')
      .eq('type_', 'area')
      .order('sort_order', { ascending: true })
      .then(response => response.data)
      .catch(err => err)
  },
  // listCount: () => {
  //   return supabase
  //     .from('lookup_values')
  //     .select('*', { count: 'exact', head: true})
  //     .eq('type_', 'area')
  //     .then(response => response?.count)
  //     .catch(err => err)
  // },
  save: (area) => CommonService.save('lookup_values', area),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id) => CommonService.delete('lookup_values', id)
}