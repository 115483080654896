// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getPayments = createAsyncThunk('appPayments/getPayments', async (jobId) => {
    try { 
      const categories = ['payment']
      const response = await DocumentService.list(jobId, categories)

      return { payments: response, paymentTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appPaymentsSlice = createSlice({
  name: 'appPayments',
  initialState: {
    selectedPayments: defaultValues,
    payments: [],
    paymentTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.paymentTableLoader = !state.paymentTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.paymentTableLoader = action.payload.paymentTableLoader
      state.payments = action.payload.payments
    })
  }
})

export const { setLoading, setTableLoader } = appPaymentsSlice.actions

export default appPaymentsSlice.reducer