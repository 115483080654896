// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {ProjectProfileService} from '@services/project-profile-service'

const defaultValues = {
  job_id: '',
  project_entity: '',
  project_date:''
}

export const getProjectProfile = createAsyncThunk('appProjectProfiles/getProjectProfile', async (jobId) => {
    try { 
      const response = await ProjectProfileService.get(jobId)
      return { projectProfile: response}
    } catch (error) {
      console.log(error)
    }
})

export const upsertProjectProfile = createAsyncThunk('appProjectProfiles/upsertProjectProfile', async (item, { dispatch }) => {
    try { 
      const response = await ProjectProfileService.save(item)
      
      if (item.job_id) {
        await dispatch(getProjectProfile(item.job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteProjectProfile = createAsyncThunk('appProjectProfiles/deleteProjectProfile', async (id, { dispatch }) => {
    try {
      const response = await ProjectProfileService.delete(id)
      await dispatch(getProjectProfile(id))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appProjectProfilesSlice = createSlice({
  name: 'appProjectProfiles',
  initialState: {
    selectedProjectProfile: defaultValues,
    projectProfile: {}
  },
  reducers: {
    selectProjectProfile: (state, action) => {
        state.selectedProjectProfile = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getProjectProfile.fulfilled, (state, action) => {
      state.projectProfile = action.payload.projectProfile
    })
  }
})

export const { setLoading, setTableLoader, setProjectProfileModal, selectProjectProfile } = appProjectProfilesSlice.actions

export default appProjectProfilesSlice.reducer