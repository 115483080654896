import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const ClientProfileService = {
  save: async (profile, onSuccess, onError) => {
    await supabase
      .from('leads')
      .update({
        name: `${profile.first_name} ${profile.last_name}`
      })
      .eq('job_id', profile.job_id)

    await CommonService.save('client_profiles', profile, onSuccess, onError)
  },
  get: (id) => {
    return supabase
      .from('client_profiles')
      .select('*')
      .eq('job_id', id)
      .filter('job_id', 'in', `(${id})`)
      // .single()
      .then(response => response.data[0])
      .catch(err => err)
  },
  delete: (id) => CommonService.delete('client_profiles', id)
}
