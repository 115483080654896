// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {DesignProfileConceptService} from '@services/design-profile-concept-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'designProfileConcept',
  sort_order: 0
}

export const getDesignProfileConcept = createAsyncThunk('appDesignProfileConcepts/getDesignProfileConcept', async (designProfileId) => {
    try { 
      const response = await DesignProfileConceptService.getAll(designProfileId)
      return { designProfileConcept: response}
    } catch (error) {
      console.log(error)
    }
})

export const insertDesignProfileConcept = createAsyncThunk('appDesignProfileConcepts/insertDesignProfileConcept', async (item, { dispatch }) => {
    try { 
      const response = await DesignProfileConceptService.save(item)
      
      if (item.design_profile_id) {
        await dispatch(getDesignProfileConcept(item.design_profile_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteBulkDesignProfileConcept = createAsyncThunk('appDesignProfileConcepts/deleteBulkDesignProfileConcept', async (items, { dispatch }) => {
    try { 
      const response = await DesignProfileConceptService.deleteAllByConceptIds(items.ids)
      
      if (items?.design_profile_id) {
        await dispatch(getDesignProfileConcept(items.design_profile_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const insertBulkDesignProfileConcept = createAsyncThunk('appDesignProfileConcepts/insertBulkDesignProfileConcept', async (items, { dispatch }) => {
    try { 
      const response = await DesignProfileConceptService.saveBulk(items)
      
      if (items[0].design_profile_id) {
        await dispatch(getDesignProfileConcept(items[0].design_profile_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteDesignProfileConcept = createAsyncThunk('appDesignProfileConcepts/deleteDesignProfileConcept', async (id, { dispatch }) => {
    try {
      const response = await DesignProfileConceptService.delete(id)
      await dispatch(getDesignProfileConcept(id))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appDesignProfileConceptsSlice = createSlice({
  name: 'appDesignProfileConcepts',
  initialState: {
    selectedDesignProfileConcept: defaultValues,
    designProfileConcept: {}
  },
  reducers: {
    selectDesignProfileConcept: (state, action) => {
        state.selectedDesignProfileConcept = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getDesignProfileConcept.fulfilled, (state, action) => {
      state.designProfileConcept = action.payload.designProfileConcept
    })
  }
})

export const { setLoading, setTableLoader, setDesignProfileConceptModal, selectDesignProfileConcept } = appDesignProfileConceptsSlice.actions

export default appDesignProfileConceptsSlice.reducer