// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getFloorPlans = createAsyncThunk('appFloorPlans/getFloorPlans', async (jobId) => {
    try { 
      const categories = ['original-floor-plan', 'furniture-layout-plan', 'false-ceiling-plan', 'electrical-plan', 'lighting-plan', 'tiles-plan', 'painting-plan', 'material-selection-plan']
      const response = await DocumentService.list(jobId, categories)

      return { floorPlans: response, floorPlanTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appFloorPlansSlice = createSlice({
  name: 'appFloorPlans',
  initialState: {
    selectedFloorPlans: defaultValues,
    floorPlans: [],
    floorPlanTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.floorPlanTableLoader = !state.floorPlanTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getFloorPlans.fulfilled, (state, action) => {
      state.floorPlanTableLoader = action.payload.floorPlanTableLoader
      state.floorPlans = action.payload.floorPlans
    })
  }
})

export const { setLoading, setTableLoader } = appFloorPlansSlice.actions

export default appFloorPlansSlice.reducer