import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const JobAreaService = {
  list: (id) => {
    return supabase
      .from('job_areas')
      .select('*,area:lookup_values(*)')
      .eq('job_id', id)
      .then(response => response.data)
      .catch(err => err)
  },
  saveBulk: (areas) => {
    return supabase
        .from('job_areas')
        .insert(areas)
        .then(savedItem => savedItem)
        .catch(err => err)
  },
  save: (area, onSuccess, onError) => CommonService.save('job_areas', area, onSuccess, onError),
  get: (id) => {
    return supabase
      .from('job_areas')
      .select('*')
      .eq('job_id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  deleteAll:  (job_id) => {
    return supabase
      .from('job_areas')
      .delete()
      .eq('job_id', job_id)
      .then(deletedItem => deletedItem)
      .catch(err => err)
  }
}
