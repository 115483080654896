import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const AppointmentService = {

  list: () => CommonService.list('appointments'),
  saveAppointmentParticipant: (participant, onSuccess, onError) => CommonService.bulkSave('appointment_participants', participant, onSuccess, onError),
  deleteAppointmentParticipant: (participants) => {

    const data = participants.map((participant) => {
      return participant.id
    })

    return supabase
        .from('appointment_participants')
        .delete()
        .in('id', data)
        .then(response => response.data)
        .catch(err => err)
  },
  // ** Prospect
  getAppointmentRepresentative: (representative_id) => {
    return supabase
      .from('appointments')
      .select('*')
      .filter('representative_id', 'in', `(${representative_id})`)
      .then(response => response.data)
      .catch(err => err)
  },
  saveAppointment: (appointment, onSuccess, onError) => CommonService.save('appointments', appointment, onSuccess, onError),
  saveOpportunity: (opportunity, onSuccess, onError) => CommonService.save('opportunities', opportunity, onSuccess, onError),
  
  // ** Opportunity
  opportunityClientAppointment: (item) => {
    return supabase
      .from('appointments')
      .select()
      .match({
        job_id: item.job_id,
        reference_type: item.type
      })
      .then(response => response.data)
      .catch(err => err)
  },
  opportunityClientAppointmentReschedule: (item) => {
    return supabase
      .from('appointments')
      .select()
      .filter('job_id', 'in', `(${item.job_id})`)
      .is('reference_type', null)
      .then(response => response.data)
      .catch(err => err)
  },
  getAppointmentParticipants: (item) => {
    return supabase
      .from('appointment_participants')
      .select('*, participantDetails:employees(*)')
      .filter('appointment_id', 'in', `(${item})`)
      .then(response => response.data)
      .catch(err => err)
  },
  OpportunityAppointmentSaved: (appointment, onSuccess, onError) => CommonService.save('appointments', appointment, onSuccess, onError)
}