// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { UserService } from '@services/user-service'

import axios from 'axios'

const defaultValues = {
  email: ''
}

export const getUsers = createAsyncThunk('appUsers/getUsers', async () => {
    try { 
      const response = await UserService.list()

      return { users: response, userTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})


export const insertUsers = createAsyncThunk('appUsers/insertUsers', async (email_) => {

  axios
      .get('http://127.0.0.1:8000/api/create/user', {
        email: email_
      })
      .then(response => {
        console.log(response.data)
      })
      .catch(err => err)

})

export const deleteUsers = createAsyncThunk('appEmployees/deleteUsers', async (id, { dispatch }) => {
  try {
    const response = await EmployeeService.delete(id)
    await dispatch(getEmployees())

    return response
  } catch (error) {
    console.log(error)
  }
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    selectedUsers: defaultValues,
    users: [],
    userTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.userTableLoader = !state.userTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.userTableLoader = action.payload.userTableLoader
      state.users = action.payload.users
    })
  }
})

export const { setLoading, setTableLoader } = appUsersSlice.actions

export default appUsersSlice.reducer
