// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getQuotations = createAsyncThunk('appQuotations/getQuotations', async (jobId) => {
    try { 
      const categories = ['quotation']
      const response = await DocumentService.list(jobId, categories)

      return { quotations: response, quotationTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appQuotationsSlice = createSlice({
  name: 'appQuotations',
  initialState: {
    selectedQuotations: defaultValues,
    quotations: [],
    quotationTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.quotationTableLoader = !state.quotationTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getQuotations.fulfilled, (state, action) => {
      state.quotationTableLoader = action.payload.quotationTableLoader
      state.quotations = action.payload.quotations
    })
  }
})

export const { setLoading, setTableLoader } = appQuotationsSlice.actions

export default appQuotationsSlice.reducer