import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const LookupDocumentService = {
  list : (categories) => {
    if (categories && categories.length > 0) {
      return supabase
      .from('lookup_documents')
      .select('*')
      .in('category', categories)
      .then(response => response.data)
      .catch(err => err)
    } else {
      return supabase
      .from('lookup_documents')
      .select('*')
      .then(response => response.data)
      .catch(err => err)
    }
    
  },
  save: (lookupDocument, onSuccess, onError) => CommonService.save('lookup_documents', lookupDocument, onSuccess, onError),
  upload: (item) => {
    return supabase
      .storage
      .from('assets')
      .upload(item.path_, item.file_, {
        cacheControl: '3600',
        upsert: false
      }).then(response => response.data)
      .catch(err => err)
  },
  getUrl: (path) => {
    return supabase
      .storage
      .from('assets')
      .getPublicUrl(path)
  },
  getUrls: (paths) => {
    return supabase
      .storage
      .from('assets')
      .getPublicUrl(paths)
      .then(response => response.data)
      .catch(err => err)
  }
  
}