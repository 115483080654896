// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {JobAreaService} from '@services/job-area-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'jobArea',
  sort_order: 0
}

export const getJobAreas = createAsyncThunk('appJobAreas/getJobAreas', async (jobId) => {
    try { 
      const response = await JobAreaService.list(jobId)
      return { jobAreas: response}
    } catch (error) {
      console.log(error)
    }
})

export const upsertJobArea = createAsyncThunk('appJobAreas/upsertJobArea', async (item, { dispatch }) => {
    try { 
      const response = await JobAreaService.save(item)
      
      if (item.job_id) {
        await dispatch(getJobAreas(item.job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const insertBulkJobAreas = createAsyncThunk('appJobAreas/insertBulkJobAreas', async (items, { dispatch }) => {
    try { 
      const response = await JobAreaService.saveBulk(items)
      
      if (items[0].job_id) {
        await dispatch(getJobAreas(items[0].job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteJobAreas = createAsyncThunk('appJobAreas/deleteJobAreas', async (jobId) => {
    try {
      const response = await JobAreaService.deleteAll(jobId)
      //await dispatch(getJobAreas(jobId))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appJobAreasSlice = createSlice({
  name: 'appJobAreas',
  initialState: {
    selectedJobArea: defaultValues,
    jobAreas: []
  },
  reducers: {
    selectJobArea: (state, action) => {
        state.selectedJobArea = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getJobAreas.fulfilled, (state, action) => {
      state.jobAreas = action.payload.jobAreas
    })
  }
})

export const { setLoading, setTableLoader, setJobAreaModal, selectJobArea } = appJobAreasSlice.actions

export default appJobAreasSlice.reducer