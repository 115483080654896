import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupVenueService = {
  listVenue: () => {
    return supabase
      .from('lookup_values')
      .select('*')
      .eq('type_', 'venue')
    //   .eq('name', 'set-appointment')
      .then(response => response.data)
      .catch(err => err)
  },
  save: (venue) => CommonService.save('lookup_values', venue),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id) => CommonService.delete('lookup_values', id)
}