import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const EmployeeService = {
  list: () => {
    return supabase
      .from('employees')
      .select('*, userRoles:user_roles(*,value:role_id,roles(*), asLeader:lookup_values(*))')
      .then(response => response.data)
      .catch(err => err)
  },

  getUserDetails: (email) => {
    return supabase
      .from('employees')
      .select('*')
      .eq('email', email)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  getTeamLeaders: () => {
    return supabase
    .from('all_team_leaders')
    .select('*')
    .then(response => response.data)
    .catch(err => err)
  },
  get: (id) => {
    return supabase
      .from('employees')
      .select('*,userRoles:user_roles(*,value:role_id,roles(*), asLeader:lookup_values(*))')
      .eq('id', id)
      .then(response => response.data)
      .catch(err => err)
  },
  save: (employee) => CommonService.save('employees', employee),
  // get: (id, onSuccess, onError) => CommonService.get('employees', id, onSuccess, onError),
  delete: (id, onSuccess, onError) => CommonService.delete('employees', id, onSuccess, onError)
}