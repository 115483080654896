// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LeadSourceService } from '@services/lead-source-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: '',
  sort_order: ''
}

export const getLookUpSource = createAsyncThunk('appLookUpSources/getLookUpSource', async () => {
    try { 
      const response = await LeadSourceService.list()
      return { types: response, lookupSourceLoader: false}
    } catch (error) {
      console.log(error)
    }
})


export const getLookupLeadSource = createAsyncThunk('appLookUpSources/getLookupLeadSource', async () => {
    try { 
      const response = await LeadSourceService.lead_source_portal()
      return { leadSource: response }
    } catch (error) {
      console.log(error)
    }
})


export const getLookupSocialMedia = createAsyncThunk('appLookUpSources/getLookupSocialMedia', async () => {
    try { 
      const response = await LeadSourceService.lead_source_social_media()
      return { socialMedia: response }
    } catch (error) {
      console.log(error)
    }
})


export const getLookUpSourceCount = createAsyncThunk('appLookUpSources/getLookUpSourceCount', async () => {
    try { 
      const response = await LeadSourceService.listCount() 
      return {  lookUpSourceCount: response }
    } catch (error) {
      console.log(error)
    }
})

export const upsertLookUpSource = createAsyncThunk('appLookUpSources/upsertLookUpSource', async (item) => {
    try { 
      const response = await LeadSourceService.save(item)
      await dispatch(getLookUpSource())
      await dispatch(setLookupSourceModal(false))
      // await dispatch(selectLookupTeam())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const deleteLookUpSource = createAsyncThunk('appLookUpSources/deleteLookUpSource', async (id) => {
    try {
      const response = await LeadSourceService.delete(id)
      // await dispatch(getLookUpSource())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appLookUpSourcesSlice = createSlice({
  name: 'appLookUpSources',
  initialState: {
    selectedLookUpSource: defaultValues,
    label:[],
    types: [],
    types: [],
    leadSource: [],
    socialMedia: [],
    lookUpSourceCount: [],
    lookupSourceLoader: true,
    lookupSourceModal: false

  },
  reducers: {
    setTableLoader: (state) => {
      state.lookupSourceLoader = !state.lookupSourceLoader
    },
    setLookupSourceModal: (state, action) => {
    if (state.lookupSourceModal) {
        state.selectedLookUpSource = defaultValues
    }
    state.lookupSourceModal = action.payload
    },
    selectSource: (state, action) => {
        state.selectedLookUpSource = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getLookUpSource.fulfilled, (state, action) => {
       state.lookupSourceLoader = action.payload.lookupSourceLoader
      state.types = action.payload.types
    }),
     builder.addCase(getLookupLeadSource.fulfilled, (state, action) => {
      state.leadSource = action.payload.leadSource
    }),
      builder.addCase(getLookupSocialMedia.fulfilled, (state, action) => {
      state.socialMedia = action.payload.socialMedia
    }),
    builder.addCase(getLookUpSourceCount.fulfilled, (state, action) => {
      state.lookUpSourceCount = action.payload.lookUpSourceCount
    })
  }
})

export const { setLoading, setTableLoader, setLookupSourceModal, selectSource } =  appLookUpSourcesSlice.actions

export default appLookUpSourcesSlice.reducer