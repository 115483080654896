// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getReceipts = createAsyncThunk('appReceipts/getReceipts', async (jobId) => {
    try { 
      const categories = ['receipt']
      console.log(jobId)
      const response = await DocumentService.list(jobId, categories)

      return { receipts: response, receiptTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appReceiptsSlice = createSlice({
  name: 'appReceipts',
  initialState: {
    selectedReceipts: defaultValues,
    receipts: [],
    receiptTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.receiptTableLoader = !state.receiptTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getReceipts.fulfilled, (state, action) => {
      state.receiptTableLoader = action.payload.receiptTableLoader
      state.receipts = action.payload.receipts
    })
  }
})

export const { setLoading, setTableLoader } = appReceiptsSlice.actions

export default appReceiptsSlice.reducer