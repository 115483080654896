import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const RoleService = {
  list: (onSuccess, onError) => CommonService.list('roles', onSuccess, onError),
  save: (role, onSuccess, onError) => CommonService.save('roles', role, onSuccess, onError),
  get: (id, onSuccess, onError) => CommonService.get('roles', id, onSuccess, onError),
  delete: (id, onSuccess, onError) => CommonService.delete('roles', id, onSuccess, onError),
  getUserRoles: (user_id) => {
    return supabase
      .from('roles')
      .select('name, label, description, user_roles!inner(user_id, role_id)')
      .eq('user_roles.user_id', user_id)
      .then(response => response.data)
      .catch(err => err)
  }
}