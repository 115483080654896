import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupDepartmentService = {
  list: () => {
    return supabase
      .from('lookup_values')
      .select('*,value:id')
      .eq('type_', 'company-department')
      .order('sort_order', { ascending: true })
      .then(response => response.data)
      .catch(err => err)
  },
  save: (department) => CommonService.save('lookup_values', department),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id, onSuccess, onError) => CommonService.delete('lookup_values', id, onSuccess, onError)
}