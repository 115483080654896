// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupDesignConceptService} from '@services/lookup-design-concept-service'
import { toast } from 'react-toastify'

const defaultValues = {
  name: '',
  description:'',
  sort_order: 0
}

export const getDesignConcepts = createAsyncThunk('appDesignConcepts/getDesignConcepts', async () => {
    try { 
      const response = await LookupDesignConceptService.list()
      
      return { designConcepts: response, designConceptTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})
export const getDesignConceptsCount = createAsyncThunk('appDesignConcepts/getDesignConceptsCount', async () => {
    try { 
      const response = await LookupDesignConceptService.listCount()
      return { designConceptsCount: response }
    } catch (error) {
      console.log(error)
    }
})

export const upsertDesignConcepts = createAsyncThunk('appDesignConcepts/upsertDesignConcepts', async (item, { dispatch }) => {
    try { 
      const response = await LookupDesignConceptService.save(item)
      await dispatch(getDesignConcepts())
      await dispatch(setDesignConceptModal(false))
      await dispatch(selectDesignConcept())

      if (response.error) {
        if (response.error.code === "23505") {
           toast.error('Area type you try add already exist',
          { hideProgressBar: true,  closeButton: false })
        } else {
          toast.error('Something went wrong',
          { hideProgressBar: true,  closeButton: false })
        }
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })
  

export const appDesignConceptsSlice = createSlice({
  name: 'appDesignConcepts',
  initialState: {
    selectedDesignConcept: defaultValues,
    designConcepts: [],
    designConceptsCount: [],
    designConceptTableLoader: true,
    designConceptsModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.designConceptTableLoader = !state.designConceptTableLoader
    },
    setDesignConceptModal: (state, action) => {
    if (state.designConceptsModal) {
        state.selectedDesignConcept = defaultValues
    }
    state.designConceptsModal = action.payload
    },
    selectDesignConcept: (state, action) => {
        state.selectedDesignConcept = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getDesignConcepts.fulfilled, (state, action) => {
      state.designConceptTableLoader = action.payload.designConceptTableLoader
      state.designConcepts = action.payload.designConcepts
    })
     builder.addCase(getDesignConceptsCount.fulfilled, (state, action) => {
      state.designConceptsCount = action.payload.designConceptsCount
    })
  }
})

export const { setLoading, setTableLoader, setDesignConceptModal, selectDesignConcept } = appDesignConceptsSlice.actions

export default appDesignConceptsSlice.reducer