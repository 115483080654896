// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupDepartmentService} from '@services/lookup-department-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'department',
  sort_order: 0
}

export const getDepartments = createAsyncThunk('appDepartments/getDepartments', async () => {
    try { 
      const response = await LookupDepartmentService.list()
      return { departments: response, departmentTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertDepartments = createAsyncThunk('appDepartments/upsertDepartments', async (item, { dispatch }) => {
    try { 
      const response = await LookupDepartmentService.save(item)
      await dispatch(getDepartments())
      await dispatch(setDepartmentModal(false))
      await dispatch(selectDepartment())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteDepartments = createAsyncThunk('appDepartments/deleteDepartments', async (id, { dispatch }) => {
    try {
      const response = await LookupDepartmentService.delete(id)
      await dispatch(getDepartments())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appDepartmentsSlice = createSlice({
  name: 'appDepartments',
  initialState: {
    selectedDepartment: defaultValues,
    departments: [],
    departmentCount: [],
    departmentTableLoader: true,
    departmentsModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.departmentTableLoader = !state.departmentTableLoader
    },
    setDepartmentModal: (state, action) => {
    if (state.departmentsModal) {
        state.selectedDepartment = defaultValues
    }
    state.departmentsModal = action.payload
    },
    selectDepartment: (state, action) => {
        state.selectedDepartment = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getDepartments.fulfilled, (state, action) => {
      state.departmentTableLoader = action.payload.departmentTableLoader
      state.departments = action.payload.departments
    })
  }
})

export const { setLoading, setTableLoader, setDepartmentModal, selectDepartment } = appDepartmentsSlice.actions

export default appDepartmentsSlice.reducer