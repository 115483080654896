import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const DesignProfileConceptService = {
  saveBulk: (concepts) => {
    return supabase
        .from('design_profile_concepts')
        .insert(concepts)
        .then(savedItem => savedItem)
        .catch(err => err)
  },
  get: (id) => {
    return supabase
      .from('design_profile_concepts')
      .select('*')
      .eq('id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  getAll: (design_profile_id) => {
    return supabase
      .from('design_profile_concepts')
      .select('*')
      .eq('design_profile_id', design_profile_id)
      .then(response => response.data)
      .catch(err => err)
  },
  delete: (design_profile_id) => {
    return supabase
      .from('design_profile_concepts')
      .delete()
      .eq('design_profile_id', design_profile_id)
      .then(deletedItem => deletedItem)
      .catch(err => err)
  },
  deleteAllByConceptIds:  (conceptIds) => {
    return supabase
      .from('design_profile_concepts')
      .delete()
      .in('id', conceptIds)
      .then(deletedItem => deletedItem)
      .catch(err => err)
  }
}
