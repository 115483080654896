// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupVenueService} from '@services/lookup-venue-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'venue',
  sort_order: 0
}

export const getVenues = createAsyncThunk('appRoles/getVenues', async () => {
    try { 
      const response = await LookupVenueService.listVenue()
      return { venues: response, venueTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertVenues = createAsyncThunk('appRoles/upsertVenues', async (item, { dispatch }) => {
    try { 
      const response = await LookupVenueService.save(item)
      await dispatch(getVenues())
      await dispatch(setVenueModal(false))
      await dispatch(selectVenue())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteVenue = createAsyncThunk('appRoles/deleteVenue', async (id, { dispatch }) => {
    try {
      const response = await LookupVenueService.delete(id)
      await dispatch(getVenues())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appRolesSlice = createSlice({
  name: 'appRoles',
  initialState: {
    selectedVenue: defaultValues,
    venues: [],
    venueTableLoader: true,
    venuesModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.venueTableLoader = !state.venueTableLoader
    },
    setVenueModal: (state, action) => {
    if (state.venuesModal) {
        state.selectedVenue = defaultValues
    }
    state.venuesModal = action.payload
    },
    selectVenue: (state, action) => {
        state.selectedVenue = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getVenues.fulfilled, (state, action) => {
      state.venueTableLoader = action.payload.venueTableLoader
      state.venues = action.payload.venues
    })
  }
})

export const { setLoading, setTableLoader, setVenueModal, selectVenue } = appRolesSlice.actions

export default appRolesSlice.reducer