// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LeadService } from '@services/lead-service'
import { savePublicFiles, uploadPublicFiles } from '../../opportunitiesUpload/store'
import { DocumentService } from '@services/document-service'

//** Utils
import { randomToken } from '@utils'

// ** Third Party Components
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

// // ** Login User Vars

const defaultValues = {
  name: '',
  phone_number: '',
  email: '',
  leads_date: '',
  source: '',
  source_details: '',
  status: '',
  reason: '',
  note: '',
  attachments: []
}

export const getLeads = createAsyncThunk('appLeads/getLeads', async (pagination) => {
  try {
    const response = await LeadService.list(pagination.from, pagination.to)

    return { leads: response, hasSearched: false, leadsTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const getPersonalLeads = createAsyncThunk('appLeads/getPersonalLeads', async (pagination) => {
  try {
    const response = await LeadService.personalList(pagination.from, pagination.to)

    return { leads: response, hasSearched: false, leadsTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const getLeadsNew = createAsyncThunk('appLeads/getLeadsNew', async (pagination) => {
  try {
    const response = await LeadService.listNew(pagination.from, pagination.to)

    return { leads: response, hasSearched: false, leadsTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})


export const getLeadsUnqualified = createAsyncThunk('appLeads/getLeadsUnqualified', async (pagination) => {
  try {
    const response = await LeadService.listUnqualified(pagination.from, pagination.to)

    return { leads: response, hasSearched: false, leadsTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreLeads = createAsyncThunk('appLeads/showMoreLeads', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { leads } = state.leads
    const response = await LeadService.list(pagination.from, pagination.to)

    const newArry = [
      ...leads,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { leads: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})


export const showMoreLeadsNew = createAsyncThunk('appLeads/showMoreLeadsNew', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { leads } = state.leads
    const response = await LeadService.listNew(pagination.from, pagination.to)

    const newArry = [
      ...leads,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { leads: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})


export const showMoreLeadsUnqualified = createAsyncThunk('appLeads/showMoreLeadsUnqualified', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { leads } = state.leads
    const response = await LeadService.listUnqualified(pagination.from, pagination.to)

    const newArry = [
      ...leads,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { leads: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const getLeadsCount = createAsyncThunk('appLeads/getLeadsCount', async () => {
  try {
    const response = await LeadService.listCount()

    return { leadsCount: response, leadsTableLoader: false }
  } catch (error) {

  }
})

export const testSearch = createAsyncThunk('appLeads/testSearch', async (searchData, { dispatch, getState }) => {
  try {
    const state = getState()
    const { leads } = state.leads
    const { data, pagination } = searchData
    const { from, to } = pagination

    await dispatch(setTableLoader(true))

    const response = await LeadService.search(data, from, to)

    let hasSearched = false
    if (data) {
      hasSearched = true
    }

    if (from >= 50) {

      const newArry = [
        ...leads,
        ...response
      ]
      const ids = newArry.map(o => o.id)
      const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))
      return { leads: filtered, hasSearched, leadsTableLoader: false, searchFilterConfiguration: data }
    } else {
      return { leads: response, hasSearched, leadsTableLoader: false, searchFilterConfiguration: data }
    }
  } catch (error) {
    console.log("ERROR")
    console.log(searchData)
    console.log(error)
  }
})

export const getLeadByJobId = createAsyncThunk('appLeads/getLeadByJobId', async (jobId) => {
  try {
    const response = await LeadService.getByJobId(jobId)

    return { lead: response }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})


export const unqualifyLead = createAsyncThunk('appLeads/unqualifyLead', async (data, { dispatch }) => {
  try {

    const leadResponse = await LeadService.bulkUpdate(data)

    // await dispatch(getLeads({from: 0, to: 9}))
    await dispatch(setUnqualifiedModal(false))
    await dispatch(selectLeads())

    return leadResponse
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})


export const assignLead = createAsyncThunk('appLeads/assignLead', async (data, { dispatch }) => {
  try {
    const data1 = data.map((obj) => {
      return {
        lead_id: obj.id,
        job_id: obj.job_id,
        created_by: obj.created_by,
        status: 'new',
        client_upload_token: randomToken(),
        representative_id: obj.assign_to
      }
    })

    const response = await LeadService.bulkUpdate(data)
    await LeadService.bulkSaveProspect(data1)
    // await dispatch(getLeads({from: 0, to: 9}))
    await dispatch(setUnqualifiedModal(false))
    await dispatch(selectLeads())

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})


export const addHistory = createAsyncThunk('appLeads/addHistory', async (data) => {
  try {
    const response = await LeadService.saveHistory(data)

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})


export const getHistory = createAsyncThunk('appLeads/getHistory', async (row) => {
  try {
    const response = await LeadService.getHistory(row)

    return { leadsHistory: response, leadsHistoryLoader: false }
  } catch (error) {
    console.log(error)
  }
})


export const updateHistoryRemarks = createAsyncThunk('appLeads/updateHistoryRemarks', async (row) => {
  try {
    const response = await LeadService.updateHistoryRemarks(row)

    return response
  } catch (error) {
    console.log(error)
  }
})

export const insertLeadAttachment = createAsyncThunk('appLeads/insertLeadAttachment', async (data, { dispatch }) => {
  try {

    let i = 0
    let uploadFile
    let fileName
    let fileNameSplits
    let fileNameWithoutExt
    let fileType
    let fileNameToSaveAs
    let filePath
    let curTime
    let result

    while (i < data.attachments.length) {
      uploadFile = data.attachments[i]
      fileName = uploadFile.name
      fileNameSplits = (typeof fileName === "undefined") ? [] : fileName.split(/[.]+/)
      fileType = (typeof fileName === "undefined") ? '' : fileNameSplits.pop()
      fileNameWithoutExt = fileNameSplits.join('.')

      curTime = new Date().getTime()

      fileNameToSaveAs = `${fileNameWithoutExt}_${curTime}.${fileType}`
      filePath = `${data.attachment_job_id}/${fileNameToSaveAs}`


      await dispatch(
        savePublicFiles({
          job_id: data.attachment_job_id,
          filename: fileName,
          file_ext: fileType,
          file_path: filePath,
          category: 'lead-attachment',
          description: ''
        }))

      result = await dispatch(
        uploadPublicFiles({
          path_: filePath,
          file_: uploadFile
        }))

      data.attachments[i].result = result

      i++
    }

    return result
  } catch (error) {
    console.log(error)
  }
})


export const insertLeadsxlsx = createAsyncThunk('appLeads/insertLeadsxlsx', async (data, { dispatch }) => {
  try {
    const response = await LeadService.xlsxInsertLeads(data)

    console.log(response)

    // // await dispatch(getLeads({from: 0, to: 9}))
    // await dispatch(setUnqualifiedModal(false))
    // await dispatch(selectLeads())

    // return leadResponse
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const upsertLeads = createAsyncThunk('appLeads/upsertLeads', async (item, { dispatch }) => {
  try {

    const leadResponse = await LeadService.saveLead(item.contact)

    if (leadResponse === "error") return "Leads already exists"

    const { id, assign_to, job_id, created_by, assignees } = leadResponse?.data[0]

    console.log("leadResponse?.data[0]", leadResponse?.data[0])

    if (item.attachments.length !== 0) {
      const attachmentData = {
        attachments: item.attachments,
        attachment_job_id: job_id
      }
      await dispatch(insertLeadAttachment(attachmentData))
    }

    if (item.deleteAttachments.length !== 0) {
      await dispatch(deleteLeadAttachment(item.deleteAttachments))
    }

    const prospect = {
      lead_id: id,
      job_id,
      created_by,
      status: 'new',
      client_upload_token: randomToken(),
      representative_id: assign_to
    }

    const updateProspect = {
      lead_id: id,
      job_id,
      created_by,
      client_upload_token: randomToken(),
      representative_id: assign_to
    }

    if (item.contact.id) {
      if (assign_to) {
        const updateReturn = await LeadService.updateProspect(updateProspect)

        console.log("updateReturn", updateReturn)

        if (!updateReturn) {
          LeadService.saveProspect(prospect)
        }

        MySwal.fire({
          icon: 'success',
          title: 'Updated!',
          text: 'Leads has been updated.',
          timer: 2500,
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    } else {

      if (assign_to) {
        LeadService.saveProspect(prospect)
      }
      MySwal.fire({
        icon: 'success',
        title: 'Generated!',
        text: 'Leads has been generated.',
        timer: 2500,
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
    }

    await dispatch(setLeadsModal(false))
    // await dispatch(selectLeads())

    if (item.type === 'submitMultiple') {
      setTimeout(() => {
        // dispatch(selectLeads())
        dispatch(setLeadsModal(true))
      }, 1000)
    }

    return leadResponse.data[0]
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const getLeadAttachment = createAsyncThunk('appLeads/getLeadAttachment', async (jobId) => {
  try {
    const categories = ['lead-attachment']
    const response = await DocumentService.list(jobId, categories)

    return { leadAttachment: response, leadsAttachmentLoader: false }
  } catch (error) {
    console.log(error)
  }
})


export const deleteLeadAttachment = createAsyncThunk('appLeads/deleteLeadAttachment', async (files) => {
  try {
    const response = await DocumentService.delete(files)

    return response
  } catch (error) {
    console.log(error)
  }
})

export const appLeadsSlice = createSlice({
  name: 'appLeads',
  initialState: {
    selectedLeads: defaultValues,
    leads: [],
    lead: [],
    searchFilterConfiguration: {},
    showMorePagination: true,
    leadAttachment: [],
    leadsCount: [],
    leadsTableLoader: true,
    leadsModal: false,
    leadsMailModal: false,
    leadsUnqualifiedModal: false,
    leadsFiltersModal: false,
    leadsReassignModal: false,
    leadsAttachmentLoader: true,
    hasSearched: false,
    resetPage: false,
    leadsHistory: [],
    leadsHistoryLoader: true,
    leadsHistoryRemarks: false,
    selectedLeadHistory: [],
    leadsShowMoreLoader: false,
    leadsUploadFileModal: false,
    pagination: { from: 0, to: 50 }
  },
  reducers: {
    setTableLoader: (state) => {
      state.leadsTableLoader = !state.leadsTableLoader
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    resetLeadsData: (state) => {
      state.leads = []
    },
    setLeadsHistoryRemarks: (state) => {
      state.leadsHistoryRemarks = !state.leadsHistoryRemarks
    },
    setHasSearch: (state) => {
      state.hasSearched = !state.hasSearched
    },
    setAttachmentLoader: (state) => {
      state.leadsAttachmentLoader = !state.leadsAttachmentLoader
    },
    setLeadsModal: (state, action) => {
      if (state.leadsModal) {
        state.selectedLeads = defaultValues
      }
      state.leadsModal = action.payload
    },
    setExportEmailModal: (state) => {
      state.leadsMailModal = !state.leadsMailModal
    },
    setLeadsUploadFileModal: (state, action) => {
      state.leadsUploadFileModal = action.payload
    },
    setUnqualifiedModal: (state, action) => {
      state.leadsUnqualifiedModal = action.payload
    },
    setLeadsFiltersModal: (state, action) => {
      state.leadsFiltersModal = action.payload
    },
    setLeadsReassignModal: (state, action) => {
      state.leadsReassignModal = action.payload
    },
    selectLeads: (state, action) => {
      state.selectedLeads = action.payload ?? defaultValues
    },
    setSelectedLeadHistory: (state, action) => {
      state.selectedLeadHistory = action.payload
    }
  },
  extraReducers: builder => {
    // GET LEADS

    builder.addCase(getLeads.pending, (state) => {
      state.leadsTableLoader = true
    }),
      builder.addCase(getLeads.fulfilled, (state, action) => {
        state.leadsTableLoader = action.payload.leadsTableLoader
        state.hasSearched = action.payload.hasSearched
        state.leads = action.payload.leads
      }),
      builder.addCase(getLeadsNew.pending, (state) => {
        state.leadsTableLoader = true
      }),
      builder.addCase(getLeadsNew.fulfilled, (state, action) => {
        state.leadsTableLoader = action.payload.leadsTableLoader
        state.hasSearched = action.payload.hasSearched
        state.leads = action.payload.leads
      }),
      builder.addCase(getLeadsUnqualified.pending, (state) => {
        state.leadsTableLoader = true
      }),
      builder.addCase(getLeadsUnqualified.fulfilled, (state, action) => {
        state.leadsTableLoader = action.payload.leadsTableLoader
        state.hasSearched = action.payload.hasSearched
        state.leads = action.payload.leads
      }),

      // SHOW MORE LEADS

      builder.addCase(showMoreLeads.pending, (state) => {
        state.leadsShowMoreLoader = true
      }),
      builder.addCase(showMoreLeads.fulfilled, (state, action) => {
        state.leads = action.payload.leads
        state.leadsShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),
      builder.addCase(showMoreLeadsNew.pending, (state) => {
        state.leadsShowMoreLoader = true
      }),
      builder.addCase(showMoreLeadsNew.fulfilled, (state, action) => {
        state.leads = action.payload.leads
        state.leadsShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),
      builder.addCase(showMoreLeadsUnqualified.pending, (state) => {
        state.leadsShowMoreLoader = true
      }),
      builder.addCase(showMoreLeadsUnqualified.fulfilled, (state, action) => {
        state.leads = action.payload.leads
        state.leadsShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),

      // OTHERS

      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.leadsHistoryLoader = action.payload.leadsHistoryLoader
        state.leadsHistory = action.payload.leadsHistory
      }),
      builder.addCase(getLeadsCount.fulfilled, (state, action) => {
        state.leadsTableLoader = action.payload.leadsTableLoader
        state.leadsCount = action.payload.leadsCount
      }),
      builder.addCase(getLeadByJobId.fulfilled, (state, action) => {
        state.lead = action.payload.lead
      })
    builder.addCase(getLeadAttachment.fulfilled, (state, action) => {
      state.leadsAttachmentLoader = action.payload.leadsAttachmentLoader
      state.leadAttachment = action.payload.leadAttachment
    })
    builder.addCase(testSearch.fulfilled, (state, action) => {
      state.leadsTableLoader = action.payload.leadsTableLoader
      state.hasSearched = action.payload.hasSearched
      state.leads = action.payload.leads
      state.searchFilterConfiguration = action.payload.searchFilterConfiguration
    })
  }
})

export const {
  setPagination,
  selectLeads,
  setLeadsModal,
  setLoading,
  setTableLoader,
  setAttachmentLoader,
  setUnqualifiedModal,
  setLeadsFiltersModal,
  setLeadsReassignModal,
  setHasSearch,
  setLeadsHistoryRemarks,
  setSelectedLeadHistory,
  resetLeadsData,
  setLeadsUploadFileModal,
  setExportEmailModal
} = appLeadsSlice.actions

export default appLeadsSlice.reducer
