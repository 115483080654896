import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupColorSchemeItemService = {
  list: () => {
    return supabase
      .from('lookup_child_values')
      .select("*,value:id")
      .eq('type_', 'color-scheme-item')
      .order('sort_order', { ascending: true })
      .then(response => response.data)
      .catch(err => err)
  },
  save: (workItem) => CommonService.save('lookup_child_values', workItem),
  get: (id, onSuccess, onError) => CommonService.get('lookup_child_values', id, onSuccess, onError),
  delete: (id) => CommonService.delete('lookup_child_values', id)
}