import { supabase } from '../configs/supabaseClient'
import { RoleService } from './role-service'
import { EmployeeService } from './employee-service'
  
const appURL = process.env.REACT_APP_BASE_URL

export const UserService = {
  // inviteByEmail: (email) => {
  //   return supabase.auth.api.inviteUserByEmail(email).then(response => response.data)
  //   .catch(err => err)
  // },
  list: () => {
    return supabase
    .from('employees')
    .select('*')
    .is('is_vosmos_enabled', true)
    .then(response => response.data)
    .catch(err => err)
  },
  save: (data) => {
    return supabase.auth.api.createUser(data)
    .then(response => response.data)
    .catch(err => err)
  },
  validate: (access_token) => {
    return supabase.auth.api.getUser(access_token)
    .then(response => response.data)
    .catch(err => err)
  },
  session: () => {
      return supabase.auth.session()
  },
  login: (email, password) => {
    return supabase.auth.signIn({
      email,
      password
    })
    .then(loginResponse => {
     const { user, error } = loginResponse

     if (error) {
        return loginResponse
     }

      const roles = new Promise((resolve, reject) => {
        return RoleService.getUserRoles(user.id).then(response => resolve(response)).catch(error => reject(error))
      })

      const details = new Promise((resolve, reject) => {
        return EmployeeService.getUserDetails(user.email).then(response => resolve(response)).catch(error => reject(error))
      })

     return Promise.all([roles, details]).then(values => {
        const results = {
          ...user,
          role: values[0],
          details: values[1]
        }

        return results
     }).catch(error => error)
    })
    .catch(err => err)
  },
  logout: (onSuccess, onError) => {
    supabase.auth.signOut().then(onSuccess).catch(onError)
  },
  isValidLogin: (onSuccess) => {
    const isValid = supabase.auth.user() !== null
    onSuccess(isValid)
  },
  sendResetPasswordEmail: (email) => {
    return supabase.auth.api
    .resetPasswordForEmail(email, {
      redirectTo: `${appURL}/reset-password`
    })
  },
  resetPassword: (newPassword) => {
    return supabase.auth.update({password: newPassword})
  },
  getLoginUser: () => {
    return supabase.auth.user()
  }
}