// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupPropertyTypeService} from '@services/lookup-property-type-service'

const defaultValues = {
  name: '',
  description:'',
  sort_order: 0
}

export const getPropertyTypes = createAsyncThunk('appPropertyTypes/getPropertyTypes', async () => {
    try { 
      const response = await LookupPropertyTypeService.list()
      
      return { propertyTypes: response, propertyTypeTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertPropertyTypes = createAsyncThunk('appPropertyTypes/upsertPropertyTypes', async (item, { dispatch }) => {
    try { 
      const response = await LookupPropertyTypeService.save(item)
      await dispatch(getPropertyTypes())
      await dispatch(setPropertyTypeModal(false))
      await dispatch(selectPropertyType())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })
  

export const appPropertyTypesSlice = createSlice({
  name: 'appPropertyTypes',
  initialState: {
    selectedPropertyType: defaultValues,
    propertyTypes: [],
    propertyTypeTableLoader: true,
    propertyTypesModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.propertyTypeTableLoader = !state.propertyTypeTableLoader
    },
    setPropertyTypeModal: (state, action) => {
    if (state.propertyTypesModal) {
        state.selectedPropertyType = defaultValues
    }
    state.propertyTypesModal = action.payload
    },
    selectPropertyType: (state, action) => {
        state.selectedPropertyType = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getPropertyTypes.fulfilled, (state, action) => {
      state.propertyTypeTableLoader = action.payload.propertyTypeTableLoader
      state.propertyTypes = action.payload.propertyTypes
    })
  }
})

export const { setLoading, setTableLoader, setPropertyTypeModal, selectPropertyType } = appPropertyTypesSlice.actions

export default appPropertyTypesSlice.reducer