import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const DocumentService = {
  list : (jobId, categories) => {
    if (categories && categories.length > 0) {
      return supabase
      .from('documents')
      .select('*')
      .eq('job_id', jobId)
      .in('category', categories)
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
    } else {
      return supabase
      .from('documents')
      .select('*')
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
    }
    
  },
  save: (document, onSuccess, onError) => CommonService.save('documents', document, onSuccess, onError),
  delete: (documents) => {
    
    const data = documents.map((document) => {
      return document.id
    })

    return supabase
        .from('documents')
        .delete()
        .in('id', data)
        .then(response => response.data)
        .catch(err => err)
  },
  upload: (item) => {
    return supabase
      .storage
      .from('jobs')
      .upload(item.path_, item.file_, {
        cacheControl: '3600',
        upsert: false
      }).then(response => response.data)
      .catch(err => err)
  },
  getUrl: (path) => {
    return supabase
      .storage
      .from('jobs')
      .createSignedUrl(path, 180)
  }
  
}