import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupColorSchemeService = {
  // list: (from, to) => {
  //   return supabase
  //     .from('lookup_values')
  //     .select("*,value:id,colorSchemeItems:lookup_child_values(*),documents:lookup_documents(*)")
  //     .eq('type_', 'color-scheme')
  //     .order('sort_order', { ascending: true })
  //     .range(from, to)
  //     .then(response => response.data)
  //     .catch(err => err)
  // },
    list: () => {
    return supabase
      .from('lookup_values')
      .select("*, value:id,colorSchemeItems:lookup_child_values(*),documents:lookup_documents(*)")
      .eq('type_', 'color-scheme')
      .order('sort_order', { ascending: true })
      .then(response => response.data)
      .catch(err => err)
  },
  listCount: () => {
    return supabase
      .from('lookup_values')
      .select('*', { count: 'exact', head: true })
      .eq('type_', 'color-scheme')
      .then(response => response?.count)
      .catch(err => err)
  },
  save: (colorScheme) => CommonService.save('lookup_values', colorScheme),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id) => CommonService.delete('lookup_values', id)

}