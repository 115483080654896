// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupBudgetTypeService} from '@services/lookup-budget-type-service'

const defaultValues = {
  name: '',
  description:'',
  sort_order: 0
}

export const getBudgetTypes = createAsyncThunk('appBudgetTypes/getBudgetTypes', async () => {
    try { 
      const response = await LookupBudgetTypeService.list()   
      return { budgetTypes: response, budgetTypeTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const getBudgetTypesCount = createAsyncThunk('appBudgetTypes/getBudgetTypesCount', async () => {
    try { 
      const response = await LookupBudgetTypeService.listCount()
      
      return { budgetTypesCount: response }
    } catch (error) {
      console.log(error)
    }
})

export const upsertBudgetTypes = createAsyncThunk('appBudgetTypes/upsertBudgetTypes', async (item, { dispatch }) => {
    try { 
      const response = await LookupBudgetTypeService.save(item)
      await dispatch(getBudgetTypes())
      await dispatch(setBudgetTypeModal(false))
      await dispatch(selectBudgetType())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })
  

export const appBudgetTypesSlice = createSlice({
  name: 'appBudgetTypes',
  initialState: {
    selectedBudgetType: defaultValues,
    budgetTypes: [],
    budgetTypesCount: [],
    budgetTypeTableLoader: true,
    budgetTypesModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.budgetTypeTableLoader = !state.budgetTypeTableLoader
    },  
    setBudgetTypeModal: (state, action) => {
    if (state.budgetTypesModal) {
        state.selectedBudgetType = defaultValues
    }
    state.budgetTypesModal = action.payload
    },
    selectBudgetType: (state, action) => {
        state.selectedBudgetType = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getBudgetTypes.fulfilled, (state, action) => {
      state.budgetTypeTableLoader = action.payload.budgetTypeTableLoader
      state.budgetTypes = action.payload.budgetTypes
    })
      builder.addCase(getBudgetTypesCount.fulfilled, (state, action) => {
      state.budgetTypesCount = action.payload.budgetTypesCount
    })
  }
})

export const { setLoading, setTableLoader, setBudgetTypeModal, selectBudgetType } = appBudgetTypesSlice.actions

export default appBudgetTypesSlice.reducer