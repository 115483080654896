// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getInteriorRenovationAgreements = createAsyncThunk('appInteriorRenovationAgreements/getInteriorRenovationAgreements', async (jobId) => {
    try { 
      const categories = ['interior-renovation-service-agreement']
      const response = await DocumentService.list(jobId, categories)

      return { interiorRenovationAgreements: response, interiorRenovationAgreementTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appInteriorRenovationAgreementsSlice = createSlice({
  name: 'appInteriorRenovationAgreements',
  initialState: {
    selectedInteriorRenovationAgreements: defaultValues,
    interiorRenovationAgreements: [],
    interiorRenovationAgreementTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.interiorRenovationAgreementTableLoader = !state.interiorRenovationAgreementTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getInteriorRenovationAgreements.fulfilled, (state, action) => {
      state.interiorRenovationAgreementTableLoader = action.payload.interiorRenovationAgreementTableLoader
      state.interiorRenovationAgreements = action.payload.interiorRenovationAgreements
    })
  }
})

export const { setLoading, setTableLoader } = appInteriorRenovationAgreementsSlice.actions

export default appInteriorRenovationAgreementsSlice.reducer