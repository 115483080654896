import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupWorkService = {
  list: () => {
    return supabase
      .from('lookup_values')
      .select("*,value:id,workItems:lookup_child_values(*)")
      .eq('type_', 'work')
      .order('sort_order', { ascending: true })
      .then(response => response.data)
      .catch(err => err)
  },
  save: (work) => CommonService.save('lookup_values', work),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id) => CommonService.delete('lookup_values', id)
}