// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupHouseTypeService} from '@services/lookup-house-type-service'

const defaultValues = {
  name: '',
  description:'',
  sort_order: 0
}

export const getHouseTypes = createAsyncThunk('appHouseTypes/getHouseTypes', async () => {
    try { 
      const response = await LookupHouseTypeService.list()
      
      return { houseTypes: response, houseTypeTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertHouseTypes = createAsyncThunk('appHouseTypes/upsertHouseTypes', async (item, { dispatch }) => {
    try { 
      const response = await LookupHouseTypeService.save(item)
      await dispatch(getHouseTypes())
      await dispatch(setHouseTypeModal(false))
      await dispatch(selectHouseType())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })
  

export const appHouseTypesSlice = createSlice({
  name: 'appHouseTypes',
  initialState: {
    selectedHouseType: defaultValues,
    houseTypes: [],
    houseTypeTableLoader: true,
    houseTypesModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.houseTypeTableLoader = !state.houseTypeTableLoader
    },
    setHouseTypeModal: (state, action) => {
    if (state.houseTypesModal) {
        state.selectedHouseType = defaultValues
    }
    state.houseTypesModal = action.payload
    },
    selectHouseType: (state, action) => {
        state.selectedHouseType = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getHouseTypes.fulfilled, (state, action) => {
      state.houseTypeTableLoader = action.payload.houseTypeTableLoader
      state.houseTypes = action.payload.houseTypes
    })
  }
})

export const { setLoading, setTableLoader, setHouseTypeModal, selectHouseType } = appHouseTypesSlice.actions

export default appHouseTypesSlice.reducer