// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AppointmentService } from '@services/appointment-service'
import { OpportunityService } from '@services/opportunity-service'
import { EmployeeService } from '@services/employee-service'
import { OpportunityCommunicationService } from '@services/opportunity-communication-service'
import { OpportunityChecklistService } from '@services/opportunity-checklist-service'

import { RoleService } from '@services/role-service'

const defaultValues = {
  name: '',
  label: '',
  description: '',
  type_: 'opportunity',
  sort_order: 0
}

const prospectAppointmentDefaultValues = {
  date: "",
  appointment_time: "",
  venue: "",
  start_time: "",
  end_time: "",
  address: "",
  reference_type: 'opportunities'
}

const opportunityCommunicationDefaultValues = {
  communication_type: "",
  scheduled_at: "",
  note: "",
  communicated_at: "",
  remarks: "",
  outcome: ""
}

export const bulkSaveOpportunities = createAsyncThunk('appLeads/bulkSaveOpportunities', async (item, { dispatch }) => {
  try {
    const response = await OpportunityService.bulkSaveOpportunities(item)
    // await dispatch(setProspectUnqualifiedModal(false))
    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


// ** Appointment Functions
export const opportunityAppointment = createAsyncThunk('appCommunication/opportunityAppointment', async (item, { dispatch }) => {
  try {
    const opportunityAppointmentResponse = await AppointmentService.OpportunityAppointmentSaved(item)
    if (opportunityAppointmentResponse?.data[0]) {
      await dispatch(setSavedOpportunityAppointment(opportunityAppointmentResponse))
    }

    return opportunityAppointmentResponse.data
  } catch (error) {
    console.log(error)
  }
})


export const getCommunication = createAsyncThunk('appLeads/getCommunication', async (row) => {
  try {
    const response = await OpportunityService.getCommunication(row)

    return { opportunitiesCommunication: response, opportunitiesCommunicationLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const opportunityExistingAppointment = createAsyncThunk('appProspects/opportunityExistingAppointment', async (item, { dispatch }) => {
  try {
    if (item.type === 'opportunities') {
      const response = await AppointmentService.opportunityClientAppointment(item)
      await dispatch(setSelectedAppointment(response))

      return { existingAppointment: response }
    } else if (item.type === null) {
      const reschedResponse = await AppointmentService.opportunityClientAppointmentReschedule(item)

      await dispatch(setSelectedAppointment(reschedResponse))
      return { existingAppointment: reschedResponse }
    }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const insertAppointmentParticipants = createAsyncThunk('appProspects/insertAppointmentParticipants', async (item, { dispatch }) => {
  try {
    AppointmentService.saveAppointmentParticipant(item)
    await dispatch(setSelectedAppointment(response))

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const deleteAppointmentParticipants = createAsyncThunk('appProspects/deleteAppointmentParticipants', async (item) => {
  try {
    AppointmentService.deleteAppointmentParticipant(item)
    //  await dispatch(setSelectedAppointment(response))

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const getAppointmentParticipants = createAsyncThunk('appProspects/getAppointmentParticipants', async (item) => {
  try {
    const response = await AppointmentService.getAppointmentParticipants(item)

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const rescheduleAppointment = createAsyncThunk('appProspects/rescheduleAppointment', async (item) => {
  try {
    const response = await AppointmentService.saveAppointment(item)

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const opportunityCommunication = createAsyncThunk('appCommunication/opportunityCommunication', async (item) => {
  try {
    const opportunityCommunicationResponse = await OpportunityCommunicationService.save(item)
    if (opportunityCommunicationResponse?.data[0]) {

      return opportunityCommunicationResponse?.data[0]
    }

  } catch (error) {
    console.log(error)
  }
})

export const getOpportunities = createAsyncThunk('appOpportunities/getOpportunities', async (pagination) => {
  try {
    const response = await OpportunityService.list(pagination.from, pagination.to)

    return { opportunities: response, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const getOpportunitiesFirstAppt = createAsyncThunk('appOpportunities/getOpportunitiesFirstAppt', async (pagination) => {
  try {
    const response = await OpportunityService.listFirstAppt(pagination.from, pagination.to)

    return { opportunities: response, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const getOpportunitiesPendingDocs = createAsyncThunk('appOpportunities/getOpportunitiesPendingDocs', async (pagination) => {
  try {
    const response = await OpportunityService.listPendingDocs(pagination.from, pagination.to)

    return { opportunities: response, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const getOpportunitiesOnGoing = createAsyncThunk('appOpportunities/getOpportunitiesOnGoing', async (pagination) => {
  try {
    const response = await OpportunityService.listOnGoing(pagination.from, pagination.to)

    return { opportunities: response, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const getOpportunitiesUnqualified = createAsyncThunk('appOpportunities/getOpportunitiesUnqualified', async (pagination) => {
  try {
    const response = await OpportunityService.listUnqualified(pagination.from, pagination.to)

    return { opportunities: response, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const showMoreOpportunities = createAsyncThunk('appLeads/showMoreOpportunities', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { opportunities } = state.opportunities
    const response = await OpportunityService.list(pagination.from, pagination.to)

    const newArry = [
      ...opportunities,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { opportunities: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreOpportunitiesFirstAppt = createAsyncThunk('appLeads/showMoreOpportunitiesFirstAppt', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { opportunities } = state.opportunities
    const response = await OpportunityService.listFirstAppt(pagination.from, pagination.to)

    const newArry = [
      ...opportunities,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { opportunities: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreOpportunitiesPendingDocs = createAsyncThunk('appLeads/showMoreOpportunitiesPendingDocs', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { opportunities } = state.opportunities
    const response = await OpportunityService.listPendingDocs(pagination.from, pagination.to)

    const newArry = [
      ...opportunities,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { opportunities: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreOpportunitiesOnGoing = createAsyncThunk('appLeads/showMoreOpportunitiesOnGoing', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { opportunities } = state.opportunities
    const response = await OpportunityService.listOnGoing(pagination.from, pagination.to)

    const newArry = [
      ...opportunities,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { opportunities: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreOpportunitiesUnqualified = createAsyncThunk('appLeads/showMoreOpportunitiesUnqualified', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { opportunities } = state.opportunities
    const response = await OpportunityService.listUnqualified(pagination.from, pagination.to)

    const newArry = [
      ...opportunities,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { opportunities: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const getOpportunitiesCount = createAsyncThunk('appOpportunities/getOpportunitiesCount', async () => {
  try {
    const response = await OpportunityService.listCount()
    return { opportunitiesCount: response }
  } catch (error) {
    console.log(error)
  }
})
export const getOpportunitiesSearch = createAsyncThunk('appOpportunities/getOpportunitiesSearch ', async (value, { dispatch }) => {
  try {
    await dispatch(setTableLoader(true))
    const response = await OpportunityService.listSearch(value)
    let hasSearched = false
    if (value !== "") {
      hasSearched = true
    }

    return { opportunities: response, hasSearched }
  } catch (error) {
    console.log(error)
  }
})

export const getOpportunity = createAsyncThunk('appOpportunities/getOpportunity', async (id) => {
  try {
    const response = await OpportunityService.get(id)
    return { opportunity: response, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})


export const opportunitySearch = createAsyncThunk('appLeads/opportunitySearch', async (searchObject, { dispatch }) => {
  try {
    await dispatch(setTableLoader(true))

    const response = await OpportunityService.search(searchObject)

    let hasSearched = false
    if (searchObject) {
      hasSearched = true
    }

    return { opportunities: response, hasSearched, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const getOpportunityCommunicationHistory = createAsyncThunk('appOpportunities/getOpportunityCommunicationHistory', async (row) => {
  try {
    const response = await OpportunityService.getCommunicationHistory(row.id, row.job_id)
    return { communications: response }
  } catch (error) {
    console.log(error)
  }
})


export const getOpportunityByJobId = createAsyncThunk('appOpportunities/getOpportunityByJobId', async (jobId) => {
  try {
    const response = await OpportunityService.getByJobId(jobId)
    return { opportunity: response, opportunityTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const upsertOpportunity = createAsyncThunk('appOpportunities/upsertOpportunity', async (item, { dispatch }) => {
  try {
    console.log("=======")
    console.log("SAVE OPPORTUNITY", item)
    const response = await OpportunityService.save(item)
    await dispatch(getOpportunities({ from: 0, to: 9 }))
    await dispatch(setOpportunityModal(false))
    await dispatch(setOpportunityLogCallModal(false))
    await dispatch(selectOpportunity())

    return response
  } catch (error) {
    console.log(error)
  }
})

export const deleteOpportunity = createAsyncThunk('appOpportunities/deleteOpportunity', async (id, { dispatch }) => {
  try {
    const response = await OpportunityService.delete(id)
    await dispatch(getOpportunities({ from: 0, to: 9 }))

    return response
  } catch (error) {
    console.log(error)
  }
})

export const upsertOpportunityChecklist = createAsyncThunk('appOpportunities/upsertOpportunityChecklist', async (item) => {
  try {
    const response = await OpportunityChecklistService.save(item)

    return response
  } catch (error) {
    console.log(error)
  }
})

export const upsertOpportunityCommunication = createAsyncThunk('appLeads/upsertOpportunityCommunication', async (item) => {
  try {
    const response = await OpportunityCommunicationService.save(item)
    // await dispatch(getOpportunities({ from: 0, to: 9 }))
    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


export const getUserRoleById = createAsyncThunk('appOpportunities/getUserRoleById', async (user_id) => {
  try {
    const response = await RoleService.getUserRoles(user_id)

    return response
  } catch (error) {
    console.log(error)
  }
})

export const getEmployeeById = createAsyncThunk('appOpportunities/getEmployeeById', async (employee_id, { dispatch }) => {
  try {
    const response = await EmployeeService.get(employee_id)
    const repreRole = await response[0].userRoles.map((r) => r.roles.label)

    await dispatch(setAppointmentRepresentativeRole(repreRole))

    return response
  } catch (error) {
    console.log(error)
  }
})

export const appOpportunitiesSlice = createSlice({
  name: 'appOpportunities',
  initialState: {
    selectedOpportunity: defaultValues,
    opportunities: [],
    communications: [],
    savedOpportunityAppointment: [],
    existingAppointment: [],
    selectedAppointment: prospectAppointmentDefaultValues,
    selectedCommunication: opportunityCommunicationDefaultValues,
    opportunity: [],
    opportunityTableLoader: true,
    opportunitiesModal: false,
    opportunitiesCreateCallModal: false,
    opportunityNextOptionCallModal: false,
    opportunityFollowUpCallModal: false,
    followUpMessageSelectionModal: false,
    whatsAppMessageModal: false,
    whatsAppMessageData: [],
    followUpMessageType: [],
    deletedParticipants: [],
    opportunitiesCount: [],
    hasSearched: false,
    showMorePagination: true,
    opportunitiesFilterModal: false,
    opportunitiesCommunication: [],
    opportunitiesCommunicationLoader: false,
    opportunityShowMoreLoader: false,
    opportunityRequalifyModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.opportunityTableLoader = !state.opportunityTableLoader
    },
    resetProspectsData: (state) => {
      state.opportunities = []
    },
    setOpportunitiesFilterModal: (state) => {
      state.opportunitiesFilterModal = !state.opportunitiesFilterModal
    },
    setOpportunityShowMoreLoader: (state) => {
      state.opportunityShowMoreLoader = !state.opportunityShowMoreLoader
    },
    setHasSearched: (state) => {
      state.hasSearched = !state.hasSearched
    },
    setOpportunityModal: (state, action) => {
      if (state.opportunitiesModal) {
        state.selectedOpportunity = defaultValues
      }
      state.opportunitiesModal = action.payload
    },
    selectOpportunity: (state, action) => {
      state.selectedOpportunity = action.payload ?? defaultValues
    },
    setSavedOpportunityAppointment: (state, action) => {
      state.savedOpportunityAppointment = action.payload?.data[0]
    },
    setOpportunityFollowUpCallModal: (state, action) => {
      state.opportunityFollowUpCallModal = action.payload
    },
    setOpportunityLogCallModal: (state, action) => {
      state.opportunityLogCallModal = action.payload
    },
    setSelectedAppointment: (state, action) => {
      state.selectedAppointment = action.payload[0] ?? prospectAppointmentDefaultValues
    },
    setParticipantsOnSelected: (state, action) => {
      state.selectedAppointment.participants = action.payload
    },
    setAppointmentTimeOnSelected: (state, action) => {
      if (action.payload.start_time === '10:00:00' && action.payload.end_time === '13:00:00') {
        state.selectedAppointment.appointment_time = 'tenToOne'
      } else if (action.payload.start_time === '10:00:00' && action.payload.end_time === '13:00:00') {
        state.selectedAppointment.appointment_time = 'twoToFive'
      } else if (action.payload.start_time === '14:00:00' && action.payload.end_time === '17:00:00') {
        state.selectedAppointment.appointment_time = 'sevenToTen'
      } else if (action.payload.start_time && action.payload.end_time) {
        state.selectedAppointment.appointment_time = 'others'
      }
    },
    setOpportunitiesCreateCallModal: (state, action) => {
      state.opportunitiesCreateCallModal = action.payload
    },
    setOpportunityNextOptionCallModal: (state, action) => {
      state.opportunityNextOptionCallModal = action.payload
    },
    setDeletedParticipants: (state, action) => {
      state.deletedParticipants = action.payload
    },
    setSelectedCommunication: (state, action) => {
      state.selectedCommunication = action.payload ?? prospectCommunicationDefaultValues
    },
    setOpportunityRequalifyModal: (state, action) => {
      state.opportunityRequalifyModal = action.payload
    },
    setFollowUpMessageSelectionModal: (state, action) => {
      state.followUpMessageSelectionModal = action.payload
    },
    setFollowUpMessageType: (state, action) => {
      state.followUpMessageType = action.payload
    },
    setWhatsAppMessageModal: (state, action) => {
      state.whatsAppMessageModal = action.payload
    },
    setwhatsAppMessageData: (state, action) => {
      state.whatsAppMessageData = action.payload
    },
    setAppointmentRepresentativeRole: (state, action) => {
      state.existingAppointment.representative_role = action.payload
    }
  },
  extraReducers: builder => {
    // GET OPPORTUNITIES
    builder.addCase(getOpportunities.pending, (state) => {
      state.opportunityTableLoader = true
    }),
      builder.addCase(getOpportunities.fulfilled, (state, action) => {
        state.opportunityTableLoader = action.payload.opportunityTableLoader
        state.opportunities = action.payload?.opportunities
      }),
      builder.addCase(getOpportunitiesFirstAppt.pending, (state) => {
        state.opportunityTableLoader = true
      }),
      builder.addCase(getOpportunitiesFirstAppt.fulfilled, (state, action) => {
        state.opportunityTableLoader = action.payload.opportunityTableLoader
        state.opportunities = action.payload?.opportunities
      }),
      builder.addCase(getOpportunitiesPendingDocs.pending, (state) => {
        state.opportunityTableLoader = true
      }),
      builder.addCase(getOpportunitiesPendingDocs.fulfilled, (state, action) => {
        state.opportunityTableLoader = action.payload.opportunityTableLoader
        state.opportunities = action.payload?.opportunities
      }),
      builder.addCase(getOpportunitiesOnGoing.pending, (state) => {
        state.opportunityTableLoader = true
      }),
      builder.addCase(getOpportunitiesOnGoing.fulfilled, (state, action) => {
        state.opportunityTableLoader = action.payload.opportunityTableLoader
        state.opportunities = action.payload?.opportunities
      }),
      builder.addCase(getOpportunitiesUnqualified.pending, (state) => {
        state.opportunityTableLoader = true
      }),
      builder.addCase(getOpportunitiesUnqualified.fulfilled, (state, action) => {
        state.opportunityTableLoader = action.payload.opportunityTableLoader
        state.opportunities = action.payload?.opportunities
      }),


      // SHOW MORE OPPORTUNITIES
      builder.addCase(showMoreOpportunities.pending, (state) => {
        state.opportunityShowMoreLoader = true
      }),
      builder.addCase(showMoreOpportunities.fulfilled, (state, action) => {
        state.opportunities = action.payload.opportunities
        state.showMorePagination = action.payload.showMorePagination,
          state.opportunityShowMoreLoader = false
      }),
      builder.addCase(showMoreOpportunitiesFirstAppt.pending, (state) => {
        state.opportunityShowMoreLoader = true
      }),
      builder.addCase(showMoreOpportunitiesFirstAppt.fulfilled, (state, action) => {
        state.opportunities = action.payload.opportunities
        state.showMorePagination = action.payload.showMorePagination
        state.opportunityShowMoreLoader = false
      }),
      builder.addCase(showMoreOpportunitiesPendingDocs.pending, (state) => {
        state.opportunityShowMoreLoader = true
      }),
      builder.addCase(showMoreOpportunitiesPendingDocs.fulfilled, (state, action) => {
        state.opportunities = action.payload.opportunities
        state.showMorePagination = action.payload.showMorePagination
        state.opportunityShowMoreLoader = false
      }),
      builder.addCase(showMoreOpportunitiesOnGoing.pending, (state) => {
        state.opportunityShowMoreLoader = true
      }),
      builder.addCase(showMoreOpportunitiesOnGoing.fulfilled, (state, action) => {
        state.opportunities = action.payload.opportunities
        state.showMorePagination = action.payload.showMorePagination
        state.opportunityShowMoreLoader = false
      }),
      builder.addCase(showMoreOpportunitiesUnqualified.pending, (state) => {
        state.opportunityShowMoreLoader = true
      }),
      builder.addCase(showMoreOpportunitiesUnqualified.fulfilled, (state, action) => {
        state.opportunities = action.payload.opportunities
        state.showMorePagination = action.payload.showMorePagination
        state.opportunityShowMoreLoader = false
      }),

      // OTHERS
      builder.addCase(opportunityExistingAppointment.fulfilled, (state, action) => {
        state.existingAppointment = action.payload.existingAppointment[0]
      }),
      builder.addCase(getOpportunity.fulfilled, (state, action) => {
        state.opportunity = action.payload.opportunity
      }),
      builder.addCase(getCommunication.fulfilled, (state, action) => {
        state.opportunitiesCommunicationLoader = action.payload.opportunitiesCommunicationLoader
        state.opportunitiesCommunication = action.payload.opportunitiesCommunication
      }),
      builder.addCase(getOpportunityCommunicationHistory.fulfilled, (state, action) => {
        state.communications = action.payload.communications
      }),
      builder.addCase(getOpportunityByJobId.fulfilled, (state, action) => {
        state.opportunity = action.payload.opportunity
      }),
      builder.addCase(getOpportunitiesCount.fulfilled, (state, action) => {
        state.opportunitiesCount = action.payload.opportunitiesCount
      }),
      builder.addCase(getOpportunitiesSearch.fulfilled, (state, action) => {
        state.hasSearched = action.payload.hasSearched
        state.opportunities = action.payload.opportunities
      })
    builder.addCase(opportunitySearch.fulfilled, (state, action) => {
      state.opportunityTableLoader = action.payload.opportunityTableLoader
      state.hasSearched = action.payload.hasSearched
      state.opportunities = action.payload.opportunities
    })
  }
})

export const {
  resetProspectsData,
  setLoading,
  setTableLoader,
  selectedCommunication,
  setSelectedCommunication,
  setOpportunityModal,
  setSavedOpportunityAppointment,
  setSelectedAppointment,
  setParticipantsOnSelected,
  setSavedOpportunityCommunications,
  selectOpportunity,
  setOpportunitiesCreateCallModal,
  setOpportunityFollowUpCallModal,
  setOpportunityNextOptionCallModal,
  setAppointmentTimeOnSelected,
  setOpportunityLogCallModal,
  setDeletedParticipants,
  setFollowUpMessageSelectionModal,
  setFollowUpMessageType,
  setWhatsAppMessageModal,
  setwhatsAppMessageData,
  setAppointmentRepresentativeRole,
  setHasSearched,
  setOpportunitiesFilterModal,
  setOpportunityShowMoreLoader,
  setOpportunityRequalifyModal
} = appOpportunitiesSlice.actions

export default appOpportunitiesSlice.reducer