import { supabase } from '../configs/supabaseClient'

export const CommonService = {
  list: (table_name) => {
    return supabase
      .from(table_name)
      .select('*')
      .then(response => response.data)
      .catch(err => err)
  },
  get: (table_name, id) => {
    return supabase
      .from(table_name)
      .select('*')
      .eq('id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  bulkSave: (table_name, data) => {
    return supabase
        .from(table_name)
        .insert(data)
        .then(response => response.data)
        .catch(err => err)
  },
  bulkDelete: (table_name, data) => {
    return supabase
        .from(table_name)
        .delete(data)
        .then(response => response.data)
        .catch(err => err)
  },
  save: (table_name, data) => {
    let saveFunction = null
    if (data.id) {
      saveFunction = supabase
        .from(table_name)
        .update(data)
        .eq('id', data.id)
    } else {
      saveFunction = supabase
        .from(table_name)
        .insert([data])
    }
   return saveFunction.then(savedItem => savedItem).catch(err => err)
  },
  delete: (table_name, id) => {
    return supabase
      .from(table_name)
      .delete()
      .eq('id', id)
      .then(deletedItem => deletedItem)
      .catch(err => err)
  }
}