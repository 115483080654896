import moment from 'moment'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateYYYYMMDD = (date) => {
    const d = new Date(date)
    let month = (d.getMonth() + 1)
    let day = d.getDate()
    const year = d.getFullYear()

    if (month <= 9) {
      month = `0${month}`
    }
    if (day <= 9) {
      day = `0${day}`
    }

    return [year, month, day].join('-')
}

export const authorizeUserRole = () => {
  const userData = getUserData()
  const userRole = userData?.role
  const checkMultipleRoles = userRole?.map((r) => r.name)
  
  const allowedRoles = ['data-administrator', 'team-leader']
  const roleResult = allowedRoles.some(i => checkMultipleRoles?.includes(i))

  return roleResult
 }


export const randomToken = () => {
  let text = ""
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  for (let i = 0; i < 12; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text + new Date().getTime()
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getUserDataValueByKey = (value) => JSON.parse(localStorage.getItem('userData'))[value]
export const getUserRole = () => JSON.parse(localStorage.getItem('userData'))['role']

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const replaceMe = (template, data) => {
  const pattern = /\[\[(\w+?)\]\]/g // {property}
  return template.replace(pattern, (_, token) => data[token] || '')
}


export const checkTimeOverlap = (start1, end1, start2, end2) => {
  const start1Moment = moment(start1, "HH:mm")
  const end1Moment = moment(end1, "HH:mm")
  const start2Moment = moment(start2, "HH:mm")
  const end2Moment = moment(end2, "HH:mm")
  
  return start1Moment.isBefore(end2Moment) && end1Moment.isAfter(start2Moment)
}