import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'
import { getUserData } from '@utils'

export const TeamService = {
  list: () => CommonService.list('teams'),
  save: (team, onSuccess, onError) => {
    console.log("final", team)
    return supabase
      .from('employees')
      .update({ leader_id: team.leader_id })
      .eq('id', team.id)
      .then(response => response.data)
      .catch(err => err)
  },
  get: (id, onSuccess, onError) => CommonService.get('teams', id, onSuccess, onError),
  getTeamMembers: (teamLeadId) => {
    return supabase
      .from('teams')
      .select('*, employeeDetails:employee_id(*)')
      .eq('team_lead_id', teamLeadId)
      .then(response => response.data)
      .catch(err => err)
  },
  getMembers: async () => {
    const userData = getUserData()
    const a = await supabase.rpc('fn_employee_id_with_children', { uid: userData?.id })
    const b = await supabase.from('employees').select('user_id').in('id', a.data)

    const employeeIds = b.data.map(item => item.user_id)

    const c = await supabase.from('all_leads')
      .select('*, created_by_details:created_by(full_name)')
      .in('created_by', employeeIds)
      .order('created_at', { ascending: false })


    return c.data
  },
  delete: (id, onSuccess, onError) => CommonService.delete('teams', id, onSuccess, onError),
  deleteAll: (employeeId) => {
    return supabase
      .from('teams')
      .delete()
      .or(`employee_id.eq.${employeeId},team_lead_id.eq.${employeeId}`)
      .then(deletedItems => deletedItems)
      .catch(err => err)
  }
}