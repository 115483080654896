// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import projectProfile from './store/projectProfile'
import clientProfile from './store/clientProfile'
import remodelling from './store/remodelling'
import designProfile from './store/designProfile'
import designProfileConcept from './store/designProfileConcept'
import designProfileUploadImage from './store/designProfileUploadImage'
import whatsappTemplate from './store/whatsappTemplate'
import teams from './store/teams'
import opportunityChecklist from './store/opportunityChecklist'

import jobArea from './store/jobArea'
import jobWork from './store/jobWork'
import jobWorkMeta from './store/jobWorkMeta'

import leads from '@src/views/pages/leads/store'
import prospects from '@src/views/pages/prospects/store'
import opportunities from '@src/views/pages/opportunities/store'
import users from '@src/views/pages/users/store'
import applicationRequirements from '@src/views/pages/jobProfile/ApplicationRequirement/store'
import floorPlans from '@src/views/pages/jobProfile/FloorPlan/store'
import artistImpressionAgreements from '@src/views/pages/jobProfile/ArtistImpressionAgreement/store'
import interiorRenovationAgreements from '@src/views/pages/jobProfile/InteriorRenovationAgreement/store'
import quotations from '@src/views/pages/jobProfile/Quotation/store'
import payments from '@src/views/pages/jobProfile/Payment/store'
import receipts from '@src/views/pages/jobProfile/Receipt/store'
import propertyTypes from '@src/views/pages/lookup/propertyTypes/store'
import budgetTypes from '@src/views/pages/lookup/budgetTypes/store'
import whatsappTemplates from '@src/views/pages/lookup/whatsappTemplates/store'
import keyCollectionTypes from '@src/views/pages/lookup/keyCollectionTypes/store'
import designConcepts from '@src/views/pages/lookup/designConcepts/store'
import areas from '@src/views/pages/lookup/areas/store'
import departments from '@src/views/pages/lookup/departments/store'
import roles from '@src/views/pages/lookup/roles/store'
import works from '@src/views/pages/lookup/works/store'
import positions from '@src/views/pages/lookup/positions/store'
import houseTypes from '@src/views/pages/lookup/houseTypes/store'
import lookupTeams from '@src/views/pages/lookup/teams/store'
import employees from '@src/views/pages/employees/store'
import colorSchemes from '@src/views/pages/lookup/colorSchemes/store'
import venues from '@src/views/pages/lookup/venue/store'
import time from '@src/views/pages/lookup/time/store'
import lookupLeadsSources from '@src/views/pages/lookupLeadsSources/store'
import calendar from '@src/views/pages/calendar/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  
  lookupLeadsSources,
  leads,
  prospects,
  opportunities,
  users,
  employees,
  applicationRequirements,
  floorPlans,
  artistImpressionAgreements,
  interiorRenovationAgreements,
  payments,
  receipts,
  quotations,
  projectProfile,
  clientProfile,
  remodelling,
  designProfile,
  designProfileConcept,
  designProfileUploadImage,
  jobArea,
  jobWork,
  jobWorkMeta,
  propertyTypes,
  budgetTypes,
  whatsappTemplates,
  keyCollectionTypes,
  areas,
  departments,
  works,
  positions,
  houseTypes,
  lookupTeams,
  designConcepts,
  colorSchemes,
  roles,
  whatsappTemplate,
  opportunityChecklist,
  venues,
  time,
  teams,
  calendar
}

export default rootReducer
