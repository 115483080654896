// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {TeamService} from '@services/team-service'

const defaultValues = {
  job_id: '',
  project_entity: '',
  project_date:''
}

export const getTeam = createAsyncThunk('appTeams/getTeam', async (jobId) => {
    try { 
      const response = await TeamService.get(jobId)
      return { Team: response }
    } catch (error) {
      console.log(error)
    }
})

export const getMembers = createAsyncThunk('appTeams/getMembers', async () => {
    try { 
      const response = await TeamService.getMembers()
      console.log(response)
      return { Members: response }
    } catch (error) {
      console.log(error)
    }
})

export const getTeamMembers = createAsyncThunk('appTeams/getTeamMembers', async (teamLeadId) => {
    try { 
      const response = await TeamService.getTeamMembers(teamLeadId)
      return { Members: response }
    } catch (error) {
      console.log(error)
    }
})

export const upsertTeam = createAsyncThunk('appTeams/upsertTeam', async (item, { dispatch }) => {
    try { 
      const response = await TeamService.save(item)
      
      if (item.job_id) {
        await dispatch(getTeam(item.job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteTeam = createAsyncThunk('appTeams/deleteTeam', async (id, { dispatch }) => {
    try {
      const response = await TeamService.delete(id)
      await dispatch(getTeam(id))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appTeamsSlice = createSlice({
  name: 'appTeams',
  initialState: {
    selectedTeam: defaultValues,
    Team: {},
    Members: []
  },
  reducers: {
    selectTeam: (state, action) => {
        state.selectedTeam = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getTeam.fulfilled, (state, action) => {
      state.Team = action.payload.Team
    }),
    builder.addCase(getTeamMembers.fulfilled, (state, action) => {
      state.Members = action.payload.Members
    }),
    builder.addCase(getMembers.fulfilled, (state, action) => {
      state.Members = action.payload.Members
    })
  }
})

export const { setLoading, setTableLoader, setTeamModal, selectTeam } = appTeamsSlice.actions

export default appTeamsSlice.reducer