import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const UserRoleService = {
  list: () => {
    return supabase
      .from('user_roles')
      .select('*')
      .then(response => response.data)
      .catch(err => err)
  },
  save: (area) => CommonService.save('user_roles', area),
  get: (id, onSuccess, onError) => CommonService.get('user_roles', id, onSuccess, onError),
  delete: (id) => CommonService.delete('user_roles', id),
  deleteAll: (email) => {
    return supabase
      .from('user_roles')
      .delete()
      .eq('email', email)
      .then(deletedItems => deletedItems)
      .catch(err => err)
  }
}