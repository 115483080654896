// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {JobWorkService} from '@services/job-work-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'jobWorks',
  sort_order: 0
}

export const getJobWorks = createAsyncThunk('appJobWorks/getJobWorks', async (jobId) => {
    try { 
      const response = await JobWorkService.list(jobId)
      return { jobWorks: response}
    } catch (error) {
      console.log(error)
    }
})

export const upsertJobWork = createAsyncThunk('appJobWorks/upsertJobWork', async (item, { dispatch }) => {
    try { 
      const response = await JobWorkService.save(item)
      
      if (item.job_id) {
        await dispatch(getJobWorks(item.job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const insertBulkJobWorks = createAsyncThunk('appJobWorks/insertBulkJobWorks', async (items, { dispatch }) => {
    try { 
      const response = await JobWorkService.saveBulk(items)
      
      if (items[0].job_id) {
        await dispatch(getJobWorks(items[0].job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteJobWorkByJobId = createAsyncThunk('appJobWorks/deleteJobWorkByJobId', async (jobId) => {
    try {
      const response = await JobWorkService.deleteAllByJobId(jobId)
      //await dispatch(getJobWorks(jobId))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteJobWorkByIds = createAsyncThunk('appJobWorks/deleteJobWorkByIds', async (jobWorkIds) => {
    try {
      const response = await JobWorkService.deleteAllByIds(jobWorkIds)
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appJobWorksSlice = createSlice({
  name: 'appJobWorks',
  initialState: {
    selectedJobWork: defaultValues,
    jobWorks: []
  },
  reducers: {
    selectJobWork: (state, action) => {
        state.selectedJobWork = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getJobWorks.fulfilled, (state, action) => {
      state.jobWorks = action.payload.jobWorks
    })
  }
})

export const { setLoading, setTableLoader, setJobWorkModal, selectJobWork } = appJobWorksSlice.actions

export default appJobWorksSlice.reducer