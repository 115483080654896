// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Supabase Services
import { ProspectService } from '@services/prospect-service'
import { LeadService } from '@services/lead-service'
import { RoleService } from '@services/role-service'
import { EmployeeService } from '@services/employee-service'
import { LookupWhatsappTemplateService } from '@services/lookup-whatsapp-template-service'
import { AppointmentService } from '@services/appointment-service'
import { ProspectCommunicationService } from '@services/prospect-communication-service'

// ** Third Party Components
import { toast } from 'react-toastify'

// ** Form Default Values
const defaultValues = {
  lead_id: '',
  representative_id: '',
  status: ''
}

const prospectAppointmentDefaultValues = {
  appointment_date: "",
  appointment_time: "",
  venue: "",
  start_time: "",
  end_time: "",
  address: "",
  participants: ""
}

const prospectCommunicationDefaultValues = {
  communication_type: "",
  scheduled_at: "",
  note: "",
  communicated_at: "",
  remarks: "",
  outcome: ""
}

// Lists And Filter Function
export const getProspects = createAsyncThunk('appProspects/getProspects', async (pagination) => {
  try {
    const response = await ProspectService.list(pagination.from, pagination.to)

    return { prospects: response, hasSearched: false, prospectTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const getProspectsNew = createAsyncThunk('appProspects/getProspectsNew', async (pagination) => {
  try {
    const response = await ProspectService.listNew(pagination.from, pagination.to)

    return { prospects: response, hasSearched: false, prospectTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


export const getProspectsCallScheduled = createAsyncThunk('appProspects/getProspectsCallScheduled', async (pagination) => {
  try {
    const response = await ProspectService.listCallScheduled(pagination.from, pagination.to)

    return { prospects: response, hasSearched: false, prospectTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


export const getProspectsUnqualified = createAsyncThunk('appProspects/getProspectsUnqualified', async (pagination) => {
  try {
    const response = await ProspectService.listUnqualified(pagination.from, pagination.to)

    return { prospects: response, hasSearched: false, prospectTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const getProspectsComplete = createAsyncThunk('appProspects/getProspectsComplete', async (pagination) => {
  try {
    const response = await ProspectService.listComplete(pagination.from, pagination.to)

    return { prospects: response, hasSearched: false, prospectTableLoader: false }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const showMoreProspects = createAsyncThunk('appLeads/showMoreProspects', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { prospects } = state.prospects
    const response = await ProspectService.list(pagination.from, pagination.to)

    const newArry = [
      ...prospects,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { prospects: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreProspectsNew = createAsyncThunk('appLeads/showMoreProspectsNew', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { prospects } = state.prospects
    const response = await ProspectService.listNew(pagination.from, pagination.to)

    const newArry = [
      ...prospects,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { prospects: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreProspectsCallScheduled = createAsyncThunk('appLeads/showMoreProspectsCallScheduled', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { prospects } = state.prospects
    const response = await ProspectService.listCallScheduled(pagination.from, pagination.to)

    const newArry = [
      ...prospects,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { prospects: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreProspectsUnqualified = createAsyncThunk('appLeads/showMoreProspectsUnqualified', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { prospects } = state.prospects
    const response = await ProspectService.listUnqualified(pagination.from, pagination.to)

    const newArry = [
      ...prospects,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { prospects: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const showMoreProspectsComplete = createAsyncThunk('appLeads/showMoreProspectsComplete', async (pagination, { getState }) => {
  try {
    const state = getState()
    const { prospects } = state.prospects
    const response = await ProspectService.listComplete(pagination.from, pagination.to)

    const newArry = [
      ...prospects,
      ...response
    ]

    const ids = newArry.map(o => o.id)
    const filtered = newArry.filter(({ id }, index) => !ids.includes(id, index + 1))

    return { prospects: filtered, showMorePagination: false }
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const addHistory = createAsyncThunk('appLeads/addHistory', async (data) => {
  try {
    const response = await ProspectService.saveHistory(data)

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false })
  }
})

export const getHistory = createAsyncThunk('appLeads/getHistory', async (row) => {
  try {
    const response = await ProspectService.getHistory(row)

    return { prospectHistory: response, prospectHistoryLoader: false }
  } catch (error) {
    console.log(error)
  }
})


export const getCommunication = createAsyncThunk('appLeads/getCommunication', async (row) => {
  try {
    const response = await ProspectService.getCommunication(row)

    return { prospectCommunication: response, prospectCommunicationLoader: false }
  } catch (error) {
    console.log(error)
  }
})


export const updateHistoryRemarks = createAsyncThunk('appLeads/updateHistoryRemarks', async (row) => {
  try {
    const response = await ProspectService.updateHistoryRemarks(row)

    return response
  } catch (error) {
    console.log(error)
  }
})

export const prospectSearch = createAsyncThunk('appLeads/prospectSearch', async (searchObject, { dispatch }) => {
  try {
    await dispatch(setTableLoader(true))
    const response = await ProspectService.search(searchObject)

    let hasSearched = false
    if (searchObject) {
      hasSearched = true
    }

    return { prospects: response, hasSearched, prospectTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const getProspectsCount = createAsyncThunk('appProspects/getProspectsCount', async () => {

  try {
    const response = await ProspectService.listCount()
    return { prospectsCount: response }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const getProspectByToken = createAsyncThunk('appOpportunities/getProspectByToken', async (token) => {
  try {
    const response = await ProspectService.getByToken(token)
    return { prospect: response, prospectTableLoader: false }
  } catch (error) {
    console.log(error)
  }
})

export const appointmentWhatsAppTemplate = createAsyncThunk('appProspects/appointmentWhatsAppTemplate', async () => {
  try {
    const response = await LookupWhatsappTemplateService.getAppointmentByName('set-appointment')

    return { whatsAppTemplate: response }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const checkAppointmentRepresentative = createAsyncThunk('appProspects/checkAppointmentRepresentative', async (item) => {
  try {
    const response = await AppointmentService.getAppointmentRepresentative(item)

    return { appointmentRepresentative: response }
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

// ** Save Functions
export const saveProspect = createAsyncThunk('appLeads/saveProspect', async (item, { dispatch }) => {
  try {
    const response = await ProspectService.save(item)
    await dispatch(setProspectUnqualifiedModal(false))
    await dispatch(setProspectRequalifyModal(false))
    await dispatch(setProspectFollowUpCallModal(false))
    await dispatch(setProspectLogCallModal(false))
    await dispatch(setProspectCreateCallModal(false))
    await dispatch(setProspectNextOptionCallModal(false))

    await dispatch(selectProspects())

    return response
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const bulkSaveProspect = createAsyncThunk('appLeads/bulkSaveProspect', async (item, { dispatch }) => {
  try {
    const response = await ProspectService.bulkSaveProspect(item)
    await dispatch(setProspectUnqualifiedModal(false))

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

// ** Save Functions
export const saveLead = createAsyncThunk('appLeads/saveLead', async (item) => {
  try {
    const response = await LeadService.saveLead(item)

    return response
  } catch (error) {
    console.log(error)
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


export const saveProspectAppointments = createAsyncThunk('appLeads/saveProspectAppointments', async (item, { dispatch }) => {
  try {
    const appointmentresponse = await AppointmentService.saveAppointment(item)
    // await dispatch(getEmployeeById(appointmentresponse.data[0].representative_id))

    await dispatch(setAppointmentSavedData(item))

    toast.success("Appointment Added",
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
    return appointmentresponse
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const saveOpportunity = createAsyncThunk('appLeads/saveOpportunity', async (item) => {
  try {

    const opportunityResponse = await AppointmentService.saveOpportunity(item)

    return opportunityResponse
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const appointmentParticipants = createAsyncThunk('appProspects/appointmentParticipants', async (item, { dispatch }) => {
  try {
    await AppointmentService.saveAppointmentParticipant(item)
    await dispatch(setSelectedAppointment(response))

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


export const upsertProspectCommunication = createAsyncThunk('appLeads/upsertProspectCommunication', async (item) => {
  try {
    const response = await ProspectCommunicationService.save(item)

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


export const setComplete = createAsyncThunk('appLeads/setComplete', async (item) => {
  try {
    const response = await ProspectCommunicationService.setStatusComplete(item)

    return response
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true, closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})


export const getEmployeeById = createAsyncThunk('appOpportunities/getEmployeeById', async (employee_id, { dispatch }) => {
  try {
    const response = await EmployeeService.get(employee_id)
    const repreRole = await response[0].userRoles.map((r) => r.roles.label)

    await dispatch(setAppointmentRepresentativeRole(repreRole))

    return response
  } catch (error) {
    console.log(error)
  }
})

export const appProspectsSlice = createSlice({
  name: 'appProspects',
  initialState: {
    appointmentRepresentative: [],
    whatsAppTemplate: [],
    selectedProspects: defaultValues,
    selectedAppointments: prospectAppointmentDefaultValues,
    selectedCommunication: prospectCommunicationDefaultValues,
    prospects: [],
    prospect: {},
    getAppointmentSavedData: [],
    prospectCommunication: [],
    prospectFilters: false,
    prospectTableLoader: true,
    prospectDataCollection: false,
    prospectLogCallModal: false,
    prospectAppointmentModal: false,
    prospectWhatsappModal: false,
    prospectFollowUpCallModal: false,
    prospectUnqualifiedModal: false,
    prospectUnansweredModal: false,
    // prospectUnresponsiveModal: false,
    prospectCreateCallModal: false,
    prospectNextOptionCallModal: false,
    prospectRequalifyModal: false,
    prospectsCount: [],
    hasSearched: false,
    showMorePagination: true,
    prospectHistory: [],
    prospectHistoryLoader: true,
    selectedProspectHistory: [],
    prospectsHistoryRemarks: false,
    prospectCommunicationLoader: false,
    prospectCommunication: [],
    prospectShowMoreLoader: false,
    prospectCommunicationLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.prospectTableLoader = !state.prospectTableLoader
    },
    resetProspectsData: (state) => {
      state.prospects = []
    },
    setProspectFilters: (state, action) => {
      state.prospectFilters = action.payload
    },
    setProspectsHistoryRemarks: (state) => {
      state.prospectsHistoryRemarks = !state.prospectsHistoryRemarks
    },
    setHasSearch: (state) => {
      state.hasSearched = !state.hasSearched
    },
    setAppointmentSavedData: (state, action) => {
      state.getAppointmentSavedData = action.payload
    },
    setProspectCreateCallModal: (state, action) => {
      state.prospectCreateCallModal = action.payload
    },
    setProspectNextOptionCallModal: (state, action) => {
      state.prospectNextOptionCallModal = action.payload
    },
    setProspectDataCollection: (state, action) => {
      state.prospectDataCollection = action.payload
    },
    setProspectRequalifyModal: (state, action) => {
      state.prospectRequalifyModal = action.payload
    },
    setProspectAppointmentModal: (state, action) => {
      state.prospectAppointmentModal = action.payload
    },
    setProspectWhatsappModal: (state, action) => {
      state.prospectWhatsappModal = action.payload
    },
    setProspectLogCallModal: (state, action) => {
      state.prospectLogCallModal = action.payload
    },
    setProspectFollowUpCallModal: (state, action) => {
      state.prospectFollowUpCallModal = action.payload
    },
    setProspectUnqualifiedModal: (state, action) => {
      state.prospectUnqualifiedModal = action.payload
    },
    setProspectUnansweredModal: (state, action) => {
      state.prospectUnansweredModal = action.payload
    },
    // setProspectUnresponsiveModal: (state, action) => {
    //   state.prospectUnresponsiveModal = action.payload
    // },
    selectProspects: (state, action) => {
      state.selectedProspects = action.payload ?? defaultValues
    },
    setSelectedCommunication: (state, action) => {
      state.selectedCommunication = action.payload ?? prospectCommunicationDefaultValues
    },
    setAppointmentRepresentativeRole: (state, action) => {
      state.getAppointmentSavedData.representative_role = action.payload
    },
    setSelectedProspectHistory: (state, action) => {
      state.selectedProspectHistory = action.payload
    }
  },
  extraReducers: builder => {
    // GET PROSPECTS

    builder.addCase(getProspects.pending, (state) => {
      state.prospectTableLoader = true
    }),
      builder.addCase(getProspects.fulfilled, (state, action) => {
        state.prospectTableLoader = action.payload.prospectTableLoader
        state.hasSearched = action.payload.hasSearched
        state.prospects = action.payload.prospects
      }),
      builder.addCase(getProspectsNew.pending, (state) => {
        state.prospectTableLoader = true
      }),
      builder.addCase(getProspectsNew.fulfilled, (state, action) => {
        state.prospectTableLoader = action.payload.prospectTableLoader
        state.hasSearched = action.payload.hasSearched
        state.prospects = action.payload.prospects
      }),
      builder.addCase(getProspectsCallScheduled.pending, (state) => {
        state.prospectTableLoader = true
      }),
      builder.addCase(getProspectsCallScheduled.fulfilled, (state, action) => {
        state.prospectTableLoader = action.payload.prospectTableLoader
        state.hasSearched = action.payload.hasSearched
        state.prospects = action.payload.prospects
      }),
      builder.addCase(getProspectsUnqualified.pending, (state) => {
        state.prospectTableLoader = true
      }),
      builder.addCase(getProspectsUnqualified.fulfilled, (state, action) => {
        state.prospectTableLoader = action.payload.prospectTableLoader
        state.hasSearched = action.payload.hasSearched
        state.prospects = action.payload.prospects
      }),

      builder.addCase(getProspectsComplete.pending, (state) => {
        state.prospectTableLoader = true
      }),
      builder.addCase(getProspectsComplete.fulfilled, (state, action) => {
        state.prospectTableLoader = action.payload.prospectTableLoader
        state.hasSearched = action.payload.hasSearched
        state.prospects = action.payload.prospects
      }),

      // SHOW MORE PROSPECTS

      builder.addCase(showMoreProspects.pending, (state) => {
        state.prospectShowMoreLoader = true
      }),
      builder.addCase(showMoreProspects.fulfilled, (state, action) => {
        state.prospects = action.payload.prospects
        state.prospectShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),
      builder.addCase(showMoreProspectsNew.pending, (state) => {
        state.prospectShowMoreLoader = true
      }),
      builder.addCase(showMoreProspectsNew.fulfilled, (state, action) => {
        state.prospects = action.payload.prospects
        state.prospectShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),
      builder.addCase(showMoreProspectsCallScheduled.pending, (state) => {
        state.prospectShowMoreLoader = true
      }),
      builder.addCase(showMoreProspectsCallScheduled.fulfilled, (state, action) => {
        state.prospects = action.payload.prospects
        state.prospectShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),
      builder.addCase(showMoreProspectsUnqualified.pending, (state) => {
        state.prospectShowMoreLoader = true
      }),
      builder.addCase(showMoreProspectsUnqualified.fulfilled, (state, action) => {
        state.prospects = action.payload.prospects
        state.prospectShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),

      builder.addCase(showMoreProspectsComplete.pending, (state) => {
        state.prospectShowMoreLoader = true
      }),
      builder.addCase(showMoreProspectsComplete.fulfilled, (state, action) => {
        state.prospects = action.payload.prospects
        state.prospectShowMoreLoader = false
        state.showMorePagination = action.payload.showMorePagination
      }),

      // OTHERS

      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.prospectHistoryLoader = action.payload.prospectHistoryLoader
        state.prospectHistory = action.payload.prospectHistory
      }),
      builder.addCase(getCommunication.pending, (state) => {
        state.prospectCommunicationLoader = true
      }),
      builder.addCase(getCommunication.fulfilled, (state, action) => {
        state.prospectCommunicationLoader = action.payload.prospectCommunicationLoader
        state.prospectCommunication = action.payload.prospectCommunication
        state.prospectCommunicationLoader = false
      }),
      builder.addCase(getProspectsCount.fulfilled, (state, action) => {
        state.prospectsCount = action.payload.prospectsCount
      }),

      builder.addCase(appointmentWhatsAppTemplate.fulfilled, (state, action) => {
        state.whatsAppTemplate = action.payload.whatsAppTemplate
      }),
      builder.addCase(checkAppointmentRepresentative.fulfilled, (state, action) => {
        state.appointmentRepresentative = action.payload.appointmentRepresentative
      }),
      builder.addCase(getProspectByToken.fulfilled, (state, action) => {
        state.prospect = action.payload.prospect
      })
    builder.addCase(prospectSearch.fulfilled, (state, action) => {
      state.prospectTableLoader = action.payload.prospectTableLoader
      state.hasSearched = action.payload.hasSearched
      state.prospects = action.payload.prospects
    })
  }
})

export const {
  selectProspects,
  selectedCommunication,
  setSelectedCommunication,
  setLoading,
  setTableLoader,
  setProspectDataCollection,
  setProspectRequalifyModal,
  setAppointmentSavedData,
  setProspectAppointmentModal,
  setProspectWhatsappModal,
  setProspectFollowUpCallModal,
  setProspectLogCallModal,
  setProspectUnqualifiedModal,
  setProspectUnansweredModal,
  // setProspectUnresponsiveModal,
  setProspectCreateCallModal,
  setProspectNextOptionCallModal,
  setAppointmentRepresentativeRole,
  setHasSearch,
  setProspectFilters,
  resetProspectsData,
  setSelectedProspectHistory,
  setProspectsHistoryRemarks
} = appProspectsSlice.actions

export default appProspectsSlice.reducer
