import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const DesignProfileService = {
  save: (profile, onSuccess, onError) => CommonService.save('design_profiles', profile, onSuccess, onError),
  get: (id) => {
    return supabase
      .from('design_profiles')
      .select('*,concepts:design_profile_concepts(*,info:lookup_values(*))')
      .eq('job_id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  delete: (id) => CommonService.delete('design_profiles', id)
}
