// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

const defaultValues = {
  job_id: '',
  category:''
}

export const getDesignProfileUploadImages = createAsyncThunk('appDesignProfileUploadImages/getDesignProfileUploadImages', async (jobId) => {
    try { 
      const categories = ['design-profile-upload-color-image']
      const response = await DocumentService.list(jobId, categories)

      return { designProfileUploadImages: response, designProfileUploadImageTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const appDesignProfileUploadImagesSlice = createSlice({
  name: 'appDesignProfileUploadImages',
  initialState: {
    selectedDesignProfileUploadImages: defaultValues,
    designProfileUploadImages: [],
    designProfileUploadImageTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.designProfileUploadImageTableLoader = !state.designProfileUploadImageTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getDesignProfileUploadImages.fulfilled, (state, action) => {
      state.designProfileUploadImageTableLoader = action.payload.designProfileUploadImageTableLoader
      state.designProfileUploadImages = action.payload.designProfileUploadImages
    })
  }
})

export const { setLoading, setTableLoader } = appDesignProfileUploadImagesSlice.actions

export default appDesignProfileUploadImagesSlice.reducer