// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Supabase Services
import { LookupWhatsappTemplateService } from '@services/lookup-whatsapp-template-service'

// ** Third Party Components
import { toast } from 'react-toastify'

// ** Lists And Filter Function
export const appointmentWhatsAppTemplate = createAsyncThunk('appProspects/appointmentWhatsAppTemplate', async (type) => {
  try { 
    const response = await LookupWhatsappTemplateService.getAppointmentByName(type)

    return { whatsAppTemplate: response}
  } catch (error) {
    toast.error(error,
      { hideProgressBar: true,  closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const appProspectsSlice = createSlice({
  name: 'appProspects',
  initialState: {
    whatsAppTemplate: []
  },
  extraReducers: builder => {
    builder.addCase(appointmentWhatsAppTemplate.fulfilled, (state, action) => {
      state.whatsAppTemplate = action.payload.whatsAppTemplate
    })
  }
})

export const { } = appProspectsSlice.actions

export default appProspectsSlice.reducer
