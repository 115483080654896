// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupTimeService} from '@services/lookup-time-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'time',
  sort_order: 0
}

export const getTime = createAsyncThunk('appRoles/getTime', async () => {
    try { 
      const response = await LookupTimeService.listTime()
      return { time: response, timeTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertTime = createAsyncThunk('appRoles/upsertTime', async (item, { dispatch }) => {
    try { 
      const response = await LookupTimeService.save(item)
      await dispatch(getTime())
      await dispatch(setTimeModal(false))
      await dispatch(selectTime())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteTime = createAsyncThunk('appRoles/deleteTime', async (id, { dispatch }) => {
    try {
      const response = await LookupTimeService.delete(id)
      await dispatch(getTime())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appRolesSlice = createSlice({
  name: 'appRoles',
  initialState: {
    selectedTime: defaultValues,
    time: [],
    timeTableLoader: true,
    timeModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.timeTableLoader = !state.timeTableLoader
    },
    setTimeModal: (state, action) => {
    if (state.timeModal) {
        state.selectedTime = defaultValues
    }
    state.timeModal = action.payload
    },
    selectTime: (state, action) => {
        state.selectedTime = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getTime.fulfilled, (state, action) => {
      state.timeTableLoader = action.payload.timeTableLoader
      state.time = action.payload.time
    })
  }
})

export const { setLoading, setTableLoader, setTimeModal, selectTime } = appRolesSlice.actions

export default appRolesSlice.reducer