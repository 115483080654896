// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupAreaService} from '@services/lookup-area-service'
import { toast } from 'react-toastify'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'area',
  sort_order: 0
}

export const getAreas = createAsyncThunk('appAreas/getAreas', async () => {
    try { 
      const response = await LookupAreaService.list()
      return { areas: response, areaTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertAreas = createAsyncThunk('appAreas/upsertAreas', async (item, { dispatch }) => {
    try { 
      const response = await LookupAreaService.save(item)
      await dispatch(getAreas())
      await dispatch(setAreaModal(false))
      await dispatch(selectArea())

      if (response.error) {
        if (response.error.code === "23505") {
           toast.error('Area type you try add already exist',
          { hideProgressBar: true,  closeButton: false })
        } else {
          toast.error('Something went wrong',
          { hideProgressBar: true,  closeButton: false })
        }
      }
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteAreas = createAsyncThunk('appAreas/deleteAreas', async (id, { dispatch }) => {
    try {
      const response = await LookupAreaService.delete(id)
      await dispatch(getAreas())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appAreasSlice = createSlice({
  name: 'appAreas',
  initialState: {
    selectedArea: defaultValues,
    areas: [],
    areasCount: [],
    areaTableLoader: true,
    areasModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.areaTableLoader = !state.areaTableLoader
    },
    setAreaModal: (state, action) => {
    if (state.areasModal) {
        state.selectedArea = defaultValues
    }
    state.areasModal = action.payload
    },
    selectArea: (state, action) => {
        state.selectedArea = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getAreas.fulfilled, (state, action) => {
      state.areaTableLoader = action.payload.areaTableLoader
      state.areas = action.payload.areas
    })
  }
})

export const { setLoading, setTableLoader, setAreaModal, selectArea } = appAreasSlice.actions

export default appAreasSlice.reducer