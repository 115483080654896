// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupWorkService} from '@services/lookup-work-service'
import {LookupWorkItemService} from '@services/lookup-work-item-service'
import { toast } from 'react-toastify'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'work',
  sort_order: 0
}

export const getWorks = createAsyncThunk('appWorks/getWorks', async () => {
    try { 
      const response = await LookupWorkService.list()
      
      return { works: response, workTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertWorks = createAsyncThunk('appWorks/upsertWorks', async (item, { dispatch }) => {
    try { 
      const response = await LookupWorkService.save(item)
      await dispatch(getWorks())
      await dispatch(setWorkModal(false))
      await dispatch(selectWork())

      if (response.error) {
        if (response.error.code === "23505") {
          toast.error('Work type you try to add already exist',
          { hideProgressBar: true,  closeButton: false })
        } else {
            toast.error('Something went wrong',
            { hideProgressBar: true,  closeButton: false })
        }
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const upsertWorkItem = createAsyncThunk('appWorks/upsertWorkitem', async (item) => {
    try { 
      const response = await LookupWorkItemService.save(item)
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteWorks = createAsyncThunk('appWorks/deleteWorks', async (id, { dispatch }) => {
    try {
      const response = await LookupWorkService.delete(id)
      await dispatch(getWorks())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appWorksSlice = createSlice({
  name: 'appWorks',
  initialState: {
    selectedWork: defaultValues,
    works: [],
    workTableLoader: true,
    worksModal: false,
    workItemsModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.workTableLoader = !state.workTableLoader
    },
    setWorkModal: (state, action) => {
    if (state.worksModal) {
        state.selectedWork = defaultValues
    }
    state.worksModal = action.payload
    },
    setWorkItemsModal: (state, action) => {
      
      state.workItemsModal = action.payload
    },
    
    selectWork: (state, action) => {
        state.selectedWork = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getWorks.fulfilled, (state, action) => {
      state.workTableLoader = action.payload.workTableLoader
      state.works = action.payload.works
    })
  }
})

export const { setLoading, setTableLoader, setWorkModal, selectWork, setWorkItemsModal } = appWorksSlice.actions

export default appWorksSlice.reducer