// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ClientProfileService } from '@services/client-profile-service'


const defaultValues = {
  storage: "",
  unique_feature: "",
  life_story: "",
  color_type: "",
  color_scheme_id: "",
  color_percentage1: "",
  color_percentage2: "",
  color_percentage3: "",
  color_picker1: "",
  color_picker2: "",
  color_picker3: "",
  project_entity: "",
  property_type: "",
  house_type: "",
  key_collection_date: "",
  key_collection_asap: false,
  renovation_date: "",
  renovation_asap: false,
  desired_residency_date: "",
  desired_residency_asap: false,
  floor_tiles: false,
  doors: false,
  toilet_fitting: false,
  highest_floor: false,
  kitchen_wall: false,
  ceiling_height: "",
  budget_type: "",
  key_collection_type: "",
  remarks: ""
}

export const getClientProfile = createAsyncThunk('appClientProfiles/getClientProfile', async (jobId) => {
  try {
    const response = await ClientProfileService.get(jobId)
    return { clientProfile: response }

  } catch (error) {
    console.log(error)
  }
})

export const upsertClientProfile = createAsyncThunk('appClientProfiles/upsertClientProfile', async (item, { dispatch }) => {
  try {
    const response = await ClientProfileService.save(item)


    if (item.job_id) {
      await dispatch(getClientProfile(item.job_id))
    }

    return response
  } catch (error) {
    console.log(error)
  }
})

export const deleteClientProfile = createAsyncThunk('appClientProfiles/deleteClientProfile', async (id, { dispatch }) => {
  try {
    const response = await ClientProfileService.delete(id)
    await dispatch(getClientProfile(id))

    return response
  } catch (error) {
    console.log(error)
  }
})

export const appClientProfilesSlice = createSlice({
  name: 'appClientProfiles',
  initialState: {
    selectedClientProfile: defaultValues,
    clientProfile: defaultValues
  },
  reducers: {
    selectClientProfile: (state, action) => {
      state.selectedClientProfile = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getClientProfile.fulfilled, (state, action) => {
      state.clientProfile = action.payload.clientProfile
    })
  }
})

export const { setLoading, setTableLoader, setClientProfileModal, selectClientProfile, selectProspectClientProfile } = appClientProfilesSlice.actions

export default appClientProfilesSlice.reducer