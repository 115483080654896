// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {RemodellingService} from '@services/remodelling-service'

const defaultValues = {
  job_id: '',
  project_entity: '',
  project_date:''
}

export const getRemodelling = createAsyncThunk('appRemodellings/getRemodelling', async (jobId) => {
    try { 
      const response = await RemodellingService.get(jobId)
      return { Remodelling: response}
    } catch (error) {
      console.log(error)
    }
})

export const upsertRemodelling = createAsyncThunk('appRemodellings/upsertRemodelling', async (item, { dispatch }) => {
    try { 
      const response = await RemodellingService.save(item)
      
      if (item.job_id) {
        await dispatch(getRemodelling(item.job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteRemodelling = createAsyncThunk('appRemodellings/deleteRemodelling', async (id, { dispatch }) => {
    try {
      const response = await RemodellingService.delete(id)
      await dispatch(getRemodelling(id))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appRemodellingsSlice = createSlice({
  name: 'appRemodellings',
  initialState: {
    selectedRemodelling: defaultValues,
    Remodelling: {},
    isLoading: true
  },
  reducers: {
    selectRemodelling: (state, action) => {
        state.selectedRemodelling = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
     builder.addCase(getRemodelling.pending, (state) => {
      state.isLoading = true
    }),
    builder.addCase(getRemodelling.fulfilled, (state, action) => {
      state.Remodelling = action.payload.Remodelling
      state.isLoading = false
    })
  }
})

export const { setLoading, setTableLoader, setRemodellingModal, selectRemodelling } = appRemodellingsSlice.actions

export default appRemodellingsSlice.reducer