// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupWhatsappTemplateService} from '@services/lookup-whatsapp-template-service'

const defaultValues = {
  name: '',
  description:'',
  sort_order: 0
}

export const getWhatsappTemplates = createAsyncThunk('appWhatsappTemplates/getWhatsappTemplates', async () => {
    try { 
      const response = await LookupWhatsappTemplateService.list()
      
      return { whatsappTemplates: response, whatsappTemplateTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertWhatsappTemplates = createAsyncThunk('appWhatsappTemplates/upsertWhatsappTemplates', async (item, { dispatch }) => {
    try { 
      const response = await LookupWhatsappTemplateService.save(item)
      await dispatch(getWhatsappTemplates())
      await dispatch(setWhatsappTemplateModal(false))
      await dispatch(selectWhatsappTemplate())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })
  

export const appWhatsappTemplatesSlice = createSlice({
  name: 'appWhatsappTemplates',
  initialState: {
    selectedWhatsappTemplate: defaultValues,
    whatsappTemplates: [],
    whatsappTemplateTableLoader: true,
    whatsappTemplatesModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.whatsappTemplateTableLoader = !state.whatsappTemplateTableLoader
    },
    setWhatsappTemplateModal: (state, action) => {
    if (state.whatsappTemplatesModal) {
        state.selectedWhatsappTemplate = defaultValues
    }
    state.whatsappTemplatesModal = action.payload
    },
    selectWhatsappTemplate: (state, action) => {
        state.selectedWhatsappTemplate = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getWhatsappTemplates.fulfilled, (state, action) => {
      state.whatsappTemplateTableLoader = action.payload.whatsappTemplateTableLoader
      state.whatsappTemplates = action.payload.whatsappTemplates
    })
  }
})

export const { setLoading, setTableLoader, setWhatsappTemplateModal, selectWhatsappTemplate } = appWhatsappTemplatesSlice.actions

export default appWhatsappTemplatesSlice.reducer