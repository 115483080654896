  import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LeadSourceService = {
  list: () => {
    return supabase
      .from('lookup_values')
      .select('*')
      .eq('type_', 'lead-source')
      .then(response => response.data)
      .catch(err => err)
  },
   lead_source_portal: () => {
    return supabase
      .from('lookup_child_values')
      .select('*')
      .eq('c_type_', 'lead-source-portal-item')
      .then(response => response.data)
      .catch(err => err)
  },
   lead_source_social_media: () => {
    return supabase
      .from('lookup_child_values')
      .select('*')
      .eq('c_type_', 'lead-source-social-media-item')
      .then(response => response.data)
      .catch(err => err)
  },
  listCount: () => {
    return supabase
      .from('lookup_values')
      .select('*', { count: 'exact', head: true })
      .eq('type_', 'lead-portal')
      .then(response => response?.count)
      .catch(err => err)
  },
  save: (lookup_leads_source) => CommonService.save('lookup_values', lookup_leads_source),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id, onSuccess, onError) => CommonService.delete('lookup_values', id, onSuccess, onError)

}
