// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupColorSchemeService} from '@services/lookup-color-scheme-service'
import {LookupDocumentService} from '@services/lookup-document-service'
import {LookupColorSchemeItemService} from '@services/lookup-color-scheme-item-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'color-scheme',
  sort_order: 0
}

export const getColorSchemes = createAsyncThunk('appColorSchemes/getColorSchemes', async () => {
    try { 
      // const response = await LookupColorSchemeService.list(pagination.from, pagination.to)
      const response = await LookupColorSchemeService.list()
      console.log(response)

      return { colorSchemes: response, colorSchemeTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

/*
const getImage = (colorImage) => {
  return colorImage.path === 'cherries';
}
*/

export const upsertColorSchemes = createAsyncThunk('appColorSchemes/upsertColorSchemes', async (item, { dispatch }) => {
    try { 
      const response = await LookupColorSchemeService.save(item)
      await dispatch(getColorSchemes())
      await dispatch(setColorSchemeModal(false))
      await dispatch(selectColorScheme())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const upsertColorSchemeItem = createAsyncThunk('appColorSchemes/upsertColorSchemeItem', async (item) => {
    try { 
      const response = await LookupColorSchemeItemService.save(item)
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteColorSchemes = createAsyncThunk('appColorSchemes/deleteColorSchemes', async (id, { dispatch }) => {
    try {
      const response = await LookupColorSchemeService.delete(id)
      await dispatch(getColorSchemes())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appColorSchemesSlice = createSlice({
  name: 'appColorSchemes',
  initialState: {
    selectedColorScheme: defaultValues,
    colorSchemes: [],
    colorCount: [],
    colorSchemeTableLoader: true,
    colorSchemesModal: false,
    colorSchemeItemsModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.colorSchemeTableLoader = !state.colorSchemeTableLoader
    },
    setColorSchemeModal: (state, action) => {
    if (state.colorSchemesModal) {
        state.selectedColorScheme = defaultValues
    }
    state.colorSchemesModal = action.payload
    },
    setColorSchemeItemsModal: (state, action) => {
      
      state.colorSchemeItemsModal = action.payload
    },
    selectColorScheme: (state, action) => {
        state.selectedColorScheme = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getColorSchemes.fulfilled, (state, action) => {
      state.colorSchemeTableLoader = action.payload.colorSchemeTableLoader
      state.colorSchemes = action.payload.colorSchemes
    })
  }
})

export const { setLoading, setTableLoader, setColorSchemeModal, selectColorScheme, setColorSchemeItemsModal } = appColorSchemesSlice.actions

export default appColorSchemesSlice.reducer