// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {DesignProfileService} from '@services/design-profile-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'designProfile',
  sort_order: 0
}

export const getDesignProfile = createAsyncThunk('appDesignProfiles/getDesignProfile', async (jobId) => {
    try { 
      const response = await DesignProfileService.get(jobId)
      return { designProfile: response}
    } catch (error) {
      console.log(error)
    }
})

export const upsertDesignProfile = createAsyncThunk('appDesignProfiles/upsertDesignProfile', async (item, { dispatch }) => {
    try { 
      const response = await DesignProfileService.save(item)
      
      if (item.job_id) {
        await dispatch(getDesignProfile(item.job_id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteDesignProfile = createAsyncThunk('appDesignProfiles/deleteDesignProfile', async (id, { dispatch }) => {
    try {
      const response = await DesignProfileService.delete(id)
      await dispatch(getDesignProfile(id))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appDesignProfilesSlice = createSlice({
  name: 'appDesignProfiles',
  initialState: {
    selectedDesignProfile: defaultValues,
    designProfile: {}
  },
  reducers: {
    selectDesignProfile: (state, action) => {
        state.selectedDesignProfile = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getDesignProfile.fulfilled, (state, action) => {
      state.designProfile = action.payload.designProfile
    })
  }
})

export const { setLoading, setTableLoader, setDesignProfileModal, selectDesignProfile } = appDesignProfilesSlice.actions

export default appDesignProfilesSlice.reducer