import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'

export const RemodellingService = {
  save: (remodelling) => {

    if (remodelling.id) {
      return supabase
        .from('remodellings')
        .update(remodelling)
        .eq('id', remodelling.id)
        .then(response => response.data)
        .catch(err => err)
    } else {
      return supabase
        .from('remodellings')
        .insert(remodelling)
        .then(response => response.data)
        .catch(err => err)
    }
  },
  get: (id) => {
    return supabase
      .from('remodellings')
      .select('*, property_type_id(*), house_type_id(*), budget_type_id(*), key_collection_type_id(*)')
      .eq('job_id', id)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  delete: (id) => CommonService.delete('remodellings', id)
}
