import { supabase } from '../configs/supabaseClient'

import { CommonService } from './common-service'

export const LookupWhatsappTemplateService = {
  list: () => {
    return supabase
      .from('lookup_values')
      .select('*,value:id')
      .eq('type_', 'whatsapp-template')
      .order('sort_order', { ascending: true })
      .then(response => response.data)
      .catch(err => err)
  },
  save: (whatsappTemplate) => CommonService.save('lookup_values', whatsappTemplate),
  get: (id, onSuccess, onError) => CommonService.get('lookup_values', id, onSuccess, onError),
  delete: (id, onSuccess, onError) => CommonService.delete('lookup_values', id, onSuccess, onError),
  getAppointmentByName: (type) => {
    return supabase
      .from('lookup_values')
      .select('*,value:id')
      .eq('type_', 'whatsapp-template')
      .eq('name', type)
      .then(response => response.data[0])
      .catch(err => err)
  }
}