// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import store from 'store'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    isAuthenticated: false,
    validateUserLoader: true
  },
  reducers: {
    handleLogin: (state, action) => {
      /* state.userData = action.payload
      state.isAuthenticated = !!action.payload.access_token
      state.validateUserLoader = false
      store.set('userData', action.payload) */
      state.userData = action.payload
      state.isAuthenticated = !!action.payload.aud && action.payload.aud === 'authenticated'
      state.validateUserLoader = false
      store.set('userData', action.payload)
    },
    handleLogout: state => {
      state.userData = {}
      state.isAuthenticated = null
      state.validateUserLoader = false

      // ** Remove user, access_token from store
      store.remove('userData')
    },
    getUserDetails: state => {
      state.isAuthenticated = !!state.userData
      state.validateUserLoader = false
    }
  }
})

export const { handleLogin, handleLogout, getUserDetails } = authSlice.actions

export default authSlice.reducer
