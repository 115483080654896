// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentService } from '@services/document-service'

// ** Third Party Components
import { toast } from 'react-toastify'

const defaultValues = {
  name: '',
  phone:'',
  email:'',
  source: '',
  source_details: '',
  status: '',
  reason: ''
}

export const savePublicFiles = createAsyncThunk('appLeads/savePublicFiles', async (item) => {
    try { 
      const response = await DocumentService.save(item)
  
      return response
    } catch (error) {
      toast.error(error,
      { hideProgressBar: true,  closeButton: false })
    }
  })

  
export const uploadPublicFiles = createAsyncThunk('appLeads/uploadPublicFiles', async (item) => {
    try { 
      const response = await DocumentService.upload(item)
  
      return response
    } catch (error) {
      toast.error(error,
      { hideProgressBar: true,  closeButton: false })
    }
  })


export const appLeadsSlice = createSlice({
  name: 'appLeads',
  initialState: {
    selectedLeads: defaultValues,
    opportunityUpload: [],
    leadsTableLoader: true
  },
  reducers: {
    setTableLoader: (state) => {
      state.leadsTableLoader = !state.leadsTableLoader
    }
  },
  extraReducers: builder => {
    builder.addCase(getLeads.fulfilled, (state, action) => {
      state.leadsTableLoader = action.payload.leadsTableLoader
      state.opportunityUpload = action.payload.opportunityUpload
    })
  }
})

export const { 
  setLoading, 
  setTableLoader
} = appLeadsSlice.actions

export default appLeadsSlice.reducer
