// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupKeyCollectionTypeService} from '@services/lookup-key-collection-type-service'

const defaultValues = {
  name: '',
  description:'',
  sort_order: 0
}

export const getKeyCollectionTypes = createAsyncThunk('appKeyCollectionTypes/getKeyCollectionTypes', async () => {
    try { 
      const response = await LookupKeyCollectionTypeService.list()
      
      return { keyCollectionTypes: response, keyCollectionTypeTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertKeyCollectionTypes = createAsyncThunk('appKeyCollectionTypes/upsertKeyCollectionTypes', async (item, { dispatch }) => {
    try { 
      const response = await LookupKeyCollectionTypeService.save(item)
      await dispatch(getKeyCollectionTypes())
      await dispatch(setKeyCollectionTypeModal(false))
      await dispatch(selectKeyCollectionType())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })
  

export const appKeyCollectionTypesSlice = createSlice({
  name: 'appKeyCollectionTypes',
  initialState: {
    selectedKeyCollectionType: defaultValues,
    keyCollectionTypes: [],
    keyCollectionTypeTableLoader: true,
    keyCollectionTypesModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.keyCollectionTypeTableLoader = !state.keyCollectionTypeTableLoader
    },
    setKeyCollectionTypeModal: (state, action) => {
    if (state.keyCollectionTypesModal) {
        state.selectedKeyCollectionType = defaultValues
    }
    state.keyCollectionTypesModal = action.payload
    },
    selectKeyCollectionType: (state, action) => {
        state.selectedKeyCollectionType = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getKeyCollectionTypes.fulfilled, (state, action) => {
      state.keyCollectionTypeTableLoader = action.payload.keyCollectionTypeTableLoader
      state.keyCollectionTypes = action.payload.keyCollectionTypes
    })
  }
})

export const { setLoading, setTableLoader, setKeyCollectionTypeModal, selectKeyCollectionType } = appKeyCollectionTypesSlice.actions

export default appKeyCollectionTypesSlice.reducer