import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'
// import { formatDateYYYYMMDD } from '@utils'
import { getUserData } from '@utils'
import { v4 as uuidv4 } from 'uuid'

export const LeadService = {
  list: (from, to) => {
    return supabase
      .from('all_leads')
      .select('*')
      .range(from, to)
      .filter('status', 'not.eq', 'prospect')
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },

  personalList: (from, to) => {
    const userData = getUserData()
    return supabase
      .from('all_leads')
      .select('*')
      .range(from, to)
      .eq('created_by', userData?.details?.id)
      // .filter('status', 'not.eq', 'prospect')
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  listNew: (from, to) => {
    return supabase
      .from('all_leads')
      .select('*')
      .range(from, to)
      .eq('status', 'new')
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  listUnqualified: (from, to) => {
    return supabase
      .from('all_leads')
      .select('*')
      .range(from, to)
      .eq('status', 'unqualified')
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  search: async (searchObject, from = 0, to = 50) => {

    // .filter('status', 'not.eq', 'prospect')

    if (searchObject.key_collection_type !== null) {
      // ?select=*&offset=0&limit=50&status=not.eq.prospect&order=created_at.desc.nullslast 

      let query = supabase
        .from('all_leads_with_remodellings')
        .select('*')
        .range(from, to).neq('status', "prospect")

      if (searchObject.name !== null) { query = query.ilike('name', `%${searchObject.name}%`) }
      if (searchObject.status !== null) { query = query.eq('status', searchObject.status) }
      if (searchObject.email !== null) { query = query.ilike('email', `%${searchObject.email}%`) }
      if (searchObject.phone !== null) { query = query.ilike('phone', `%${searchObject.phone}%`) }
      if (searchObject.reason !== null) { query = query.ilike('reason', `%${searchObject.reason}%`) }
      if (searchObject.source !== null) { query = query.eq('source', searchObject.source) }
      if (searchObject.assign_to !== null) { query = query.eq('assign_to', searchObject.assign_to) }
      if (searchObject.from !== null && searchObject.to !== null) { query = query.gt('leads_date', searchObject.from.toLocaleString()).lt('leads_date', searchObject.to.toLocaleString()) }

      const response = await query

      const filteredDAta = response.data.filter((item) => item.remodellings.key_collection_type_id === searchObject.key_collection_type)

      return filteredDAta

    } else {
      let query = supabase
        .from('all_leads')
        .select('*')
        .range(from, to)

      if (searchObject.name !== null) { query = query.ilike('name', `%${searchObject.name}%`) }
      if (searchObject.status !== null) { query = query.eq('status', searchObject.status) }
      if (searchObject.email !== null) { query = query.ilike('email', `%${searchObject.email}%`) }
      if (searchObject.phone !== null) { query = query.ilike('phone', `%${searchObject.phone}%`) }
      if (searchObject.reason !== null) { query = query.ilike('reason', `%${searchObject.reason}%`) }
      if (searchObject.source !== null) { query = query.eq('source', searchObject.source) }
      if (searchObject.assign_to !== null) { query = query.eq('assign_to', searchObject.assign_to) }
      if (searchObject.from !== null && searchObject.to !== null) { query = query.gt('leads_date', searchObject.from.toLocaleString()).lt('leads_date', searchObject.to.toLocaleString()) }

      const response = await query
      return response.data
    }


  },
  saveHistory: async (row) => {
    return supabase
      .from('job_histories')
      .insert(row, { returning: "minimal" })
      .then(response => response.data)
      .catch(err => err)
  },
  updateHistoryRemarks: async (row) => {
    return supabase
      .from('job_histories')
      .update({ remarks: row.remarks })
      .eq('id', row.id)
      .then(response => response.data)
      .catch(err => err)
  },
  getHistory: async (id) => {
    return supabase
      .from('job_histories')
      .select('*')
      // .match({ id: "4bafc4da-71a0-4177-a028-375924207ca7"})
      .eq("stage", "lead")
      .eq("job_id", id)
      .then(response => response.data)
      .catch(err => err)
  },
  bulkUpdate: async (row) => {
    return supabase
      .from('leads')
      .upsert(row)
      .then(response => response.data)
      .catch(err => err)
  },
  bulkSaveProspect: async (row) => {
    return supabase
      .from('prospects')
      .insert(row)
      .then(response => response.data)
      .catch(err => err)
  },
  listCount: async (data) => {
    const getLeadCounts = await supabase.rpc('fn_get_all_leads_count', data)
      .then(response => response.data)
      .catch(err => err)

    const counts = {
      all: getLeadCounts.all_leads_count,
      new: getLeadCounts.new_leads_count,
      unqualified: getLeadCounts.unqualified_leads_count
    }

    return counts
  },
  xlsxInsertLeads: async (leads) => {
    const { data, error } = await supabase.rpc('fn_insert_leads_and_users', { leads })

    if (error) {
      return error
    } else {
      return data
    }
  },
  saveLead: async (lead, onSuccess, onError) => {
    const { email, name, phone } = lead

    const { data, error } = await supabase
      .from('leads')
      .select('id')
      .eq("phone", phone)
      .limit(1)

    if (!data.length) {
      const saveData = await CommonService.save('leads', lead, onSuccess, onError)
      return saveData
    } else {
      return "error"
    }
  },
  getByJobId: (getByJobId) => {
    return supabase
      .from('all_leads')
      .select('*')
      .eq('job_id', getByJobId)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  saveProspect: (prospect, onSuccess, onError) => CommonService.save('prospects', prospect, onSuccess, onError),
  updateProspect: (prospect) => {
    return supabase
      .from('prospects')
      .update(prospect)
      .match({ lead_id: prospect.lead_id })
      .then(response => response.data)
      .catch(err => err)
  },
  // save: (lead, onError) => {
  //   let saveFunction = null
  //   saveFunction = supabase
  //   .from('leads')
  //   .upsert(lead)
  //   .eq('id', lead.id)

  //   saveFunction.then(savedItem => { 
  //       if (!lead.id) {
  //         const userData = getUserData()
  //         const { role } = userData || {}
  //         const { id, assign_to, job_id } = savedItem.data[0] || {}
  //         const checkRole = role.map((r) => r.name)

  //         const prospect = {
  //           lead_id: id,
  //           job_id,
  //           prospect_status: 'new',
  //           client_upload_token: randomToken(),
  //           representative_id: checkRole.includes('administrator') ? assign_to : userData.id
  //         }

  //         CommonService.save('prospects', prospect)

  //       } 
  //     }
  //   ).catch(onError)
  // },
  get: (id) => CommonService.get('leads', id)
}
