import { CommonService } from './common-service'
import { supabase } from '../configs/supabaseClient'
import moment from 'moment'

export const ProspectService = {
  // list: () => CommonService.list('all_prospects'),
  list: (from, to) => {
    return supabase
      .from('all_prospects')
      .select('*')
      .filter('status', 'not.eq', 'opportunity')
      .filter('status', 'not.eq', 'unqualified')
      .filter('status', 'not.eq', 'complete')
      .range(from, to)
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  listNew: (from, to) => {
    return supabase
      .from('all_prospects')
      .select('*')
      .eq('status', 'new')
      .range(from, to)
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  listCallScheduled: (from, to) => {
    return supabase
      .from('all_prospects')
      .select('*')
      .eq('status', 'call-scheduled')
      .range(from, to)
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  listUnqualified: (from, to) => {
    return supabase
      .from('all_prospects')
      .select('*')
      .eq('status', 'unqualified')
      .range(from, to)
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  listComplete: (from, to) => {
    return supabase
      .from('all_prospects')
      .select('*')
      .eq('status', 'complete')
      .range(from, to)
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  bulkSaveProspect: async (row) => {
    return supabase.rpc('fn_update_many_projects', { payload: row })
      .then(response => response.data)
      .catch(err => err)
  },
  search: async (searchObject) => {

    let query = supabase
      .from('all_prospects')
      .select('*')

    if (searchObject.leads_name !== null) { query = query.ilike('leads_name', `%${searchObject.leads_name}%`) }
    // if (searchObject.email !== null)  { query = query.like('email', `%${searchObject.email}%`) }
    if (searchObject.lead_phone !== null) { query = query.ilike('lead_phone', `%${searchObject.lead_phone}%`) }
    if (searchObject.status !== null) { query = query.eq('status', searchObject.status) }
    if (searchObject.lead_key_collection_type !== null) { query = query.eq('key_collection_type_id', searchObject.lead_key_collection_type) }
    if (searchObject.reason !== null) { query = query.ilike('unqualify_reason', `%${searchObject.reason}%`) }
    if (searchObject.representative_name !== null) { query = query.eq('representative_id', searchObject.representative_name) }
    if (searchObject.from !== null && searchObject.to !== null) { query = query.gt('created_at', moment(searchObject.from).format("YYYY-MM-DD")).lt('created_at', moment(searchObject.to).format("YYYY-MM-DD")) }


    console.log("query")
    console.log(eval(query))

    const response = await query
    return response.data
  },
  saveHistory: async (row) => {
    return supabase
      .from('job_histories')
      .insert(row)
      .then(response => response.data)
      .catch(err => err)
  },
  getHistory: async (row) => {
    return supabase
      .from('job_histories')
      .select('*, user_details:action_by(full_name), target_details:event_target_id(full_name)')
      .eq('job_id', row)
      .eq('stage', 'prospect')
      .then(response => response.data)
      .catch(err => err)
  },
  getCommunication: async (row) => {
    return supabase
      .from('communications')
      .select('*')
      .eq('job_id', row)
      .eq('stage', 'prospect')
      .order('created_at', { ascending: false })
      .then(response => response.data)
      .catch(err => err)
  },
  updateHistoryRemarks: async (row) => {
    return supabase
      .from('job_histories')
      .update({ remarks: row.remarks })
      .eq('id', row.id)
      .then(response => response.data)
      .catch(err => err)
  },
  listCount: async () => {
    const getProspectsCounts = await supabase.rpc('fn_get_all_prospects_count')
      .then(response => response.data)
      .catch(err => err)

    const counts = {
      all: getProspectsCounts.all_count,
      new: getProspectsCounts.new_count,
      callScheduled: getProspectsCounts.call_scheduled_count,
      unqualified: getProspectsCounts.unqualified_count
      // opportunity: opportunityStatus.count
    }

    return counts
  },
  save: (prospect, onSuccess, onError) => CommonService.save('prospects', prospect, onSuccess, onError),
  getByJobId: (jobId) => {
    return supabase
      .from(table_name)
      .select('*')
      .eq('job_id', jobId)
      .order('created_at', { ascending: false })
      .limit(1)
      .single()
      .then(response => response.data)
      .catch(err => err)
  },
  getByToken: (token) => {
    return supabase
      .from('all_public_prospects')
      .select('*')
      .eq('client_upload_token', token)
      .order('created_at', { ascending: false })
      .limit(1)
      .single()
      .then(response => response.data)
      .catch(err => err)
  }
}