// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { OpportunityChecklistService } from '@services/opportunity-checklist-service'
import { DocumentService } from '../../services/document-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'opportunityChecklist',
  sort_order: 0
}

export const getOpportunityChecklist = createAsyncThunk('appOpportunityChecklists/getOpportunityChecklists', async (id) => {
    try { 
      const response = await OpportunityChecklistService.get(id)

      return { opportunityChecklist: response}
    } catch (error) {
      console.log(error)
    }
})


export const checkClientProfile = createAsyncThunk('appOpportunityChecklists/checkClientProfile', async (job_id) => {
  try { 
    const response = await OpportunityChecklistService.checkClientProfileByJobId(job_id)
    if (response) {
      return { clientProfile: response }
    }

  } catch (error) {
    console.log(error)
  }
})


export const checkDesignProfile = createAsyncThunk('appOpportunityChecklists/checkDesignProfile', async (job_id) => {
  try { 
    const response = await OpportunityChecklistService.checkDesignProfileByJobId(job_id)
    if (response) {
      return { designProfile: response }
    }

  } catch (error) {
    console.log(error)
  }
})


export const uploadedDocuments = createAsyncThunk('appOpportunityChecklists/uploadedDocuments', async (job_id) => {
  try { 
    const response = await OpportunityChecklistService.checkUploadedDocumentsByJobId(job_id)
    if (response) {
      return { documents: response }
    }


  } catch (error) {
    console.log(error)
  }
})

export const uploadedOriginalFloorPlans = createAsyncThunk('appOpportunityChecklists/uploadedOriginalFloorPlans', async (job_id) => {
  try { 
    const response = await DocumentService.list(job_id, ['original-floor-plan'])
    if (response) {
      return { documents: response }
    }


  } catch (error) {
    console.log(error)
  }
})


export const saveOpportunityCheckList = createAsyncThunk('appLeads/saveOpportunityCheckList', async (item) => {
  try { 
    const response = await OpportunityChecklistService.save(item)
    
    return response
  } catch (error) {
    toast.error(error,
    { hideProgressBar: true,  closeButton: false, position: toast.POSITION.TOP_LEFT })
  }
})

export const upsertOpportunityChecklists = createAsyncThunk('appOpportunityChecklists/upsertOpportunityChecklists', async (item, { dispatch }) => {
    try { 
      const response = await OpportunityChecklistService.save(item)
      
      if (item.id) {
        await dispatch(getOpportunityChecklist(item.id))
      }
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteOpportunityChecklists = createAsyncThunk('appOpportunityChecklists/deleteOpportunityChecklists', async (id, { dispatch }) => {
    try {
      const response = await OpportunityChecklistService.delete(id)
      await dispatch(getOpportunityChecklist(id))
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appOpportunityChecklistsSlice = createSlice({
  name: 'appOpportunityChecklists',
  initialState: {
    selectedOpportunityChecklist: defaultValues,
    opportunityChecklist: [],
    clientProfile: [],
    designProfile: [],
    documents: []
  },
  reducers: {
    selectOpportunityChecklist: (state, action) => {
        state.selectedOpportunityChecklist = action.payload ?? defaultValues
    }
  },
  extraReducers: builder => {
    builder.addCase(getOpportunityChecklist.fulfilled, (state, action) => {
      state.opportunityChecklist = action.payload.opportunityChecklist
    }),
    builder.addCase(checkClientProfile.fulfilled, (state, action) => {
       state.clientProfile = action.payload.clientProfile
    }),
    builder.addCase(checkDesignProfile.fulfilled, (state, action) => {
       state.designProfile = action.payload.designProfile
    }),
    builder.addCase(uploadedDocuments.fulfilled, (state, action) => {
       state.documents = action.payload.documents
    })
  }
})

export const { setLoading, setTableLoader, setOpportunityChecklistModal, selectOpportunityChecklist } = appOpportunityChecklistsSlice.actions

export default appOpportunityChecklistsSlice.reducer