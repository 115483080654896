// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {LookupTeamService} from '@services/lookup-team-service'

const defaultValues = {
  name: '',
  label: '',
  description:'',
  type_: 'lookupTeam',
  sort_order: 0
}

export const getLookupTeams = createAsyncThunk('appLookupTeams/getLookupTeams', async () => {
    try { 
      const response = await LookupTeamService.list()
      return { lookupTeams: response, lookupTeamTableLoader: false }
    } catch (error) {
      console.log(error)
    }
})

export const upsertLookupTeams = createAsyncThunk('appLookupTeams/upsertLookupTeams', async (item, { dispatch }) => {
    try { 
      const response = await LookupTeamService.save(item)
      await dispatch(getLookupTeams())
      await dispatch(setLookupTeamModal(false))
      await dispatch(selectLookupTeam())
      
      return response
    } catch (error) {
      console.log(error)
    }
  })

  export const deleteLookupTeams = createAsyncThunk('appLookupTeams/deleteLookupTeams', async (id, { dispatch }) => {
    try {
      const response = await LookupTeamService.delete(id)
      await dispatch(getLookupTeams())
  
      return response
    } catch (error) {
      console.log(error)
    }
  })

export const appLookupTeamsSlice = createSlice({
  name: 'appLookupTeams',
  initialState: {
    selectedLookupTeam: defaultValues,
    lookupTeams: [],
    lookupTeamTableLoader: true,
    lookupTeamsModal: false
  },
  reducers: {
    setTableLoader: (state) => {
      state.lookupTeamTableLoader = !state.lookupTeamTableLoader
    },
    setLookupTeamModal: (state, action) => {
    if (state.lookupTeamsModal) {
        state.selectedLookupTeam = defaultValues
    }
    state.lookupTeamsModal = action.payload
    },
    selectLookupTeam: (state, action) => {
        state.selectedLookupTeam = action.payload ?? defaultValues
      }
  },
  extraReducers: builder => {
    builder.addCase(getLookupTeams.fulfilled, (state, action) => {
      state.lookupTeamTableLoader = action.payload.lookupTeamTableLoader
      state.lookupTeams = action.payload.lookupTeams
    })
  }
})

export const { setLoading, setTableLoader, setLookupTeamModal, selectLookupTeam } = appLookupTeamsSlice.actions

export default appLookupTeamsSlice.reducer